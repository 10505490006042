import { createContext, useContext, useEffect, useRef, useState } from 'react';
import useCancelToken from '@truescope-web/react/lib/hooks/useCancelToken';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';

import ExtractedEntityType from '@truescope/utils/lib/mediaItems/ExtractedEntityType';
import extractedEntityTypeOptions from '@truescope/utils/lib/mediaItems/extractedEntityTypeOptions';

import stringFilterComparisonType from '@truescope/utils/lib/filters/stringFilterComparisonType';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { getSocialNetworks, searchEntities } from './constants';

const EntitiesProvider = createContext({});

export const useEntities = () => {
	const context = useContext(EntitiesProvider);
	if (context === undefined) {
		throw new Error('useEntities must be used within a EntitiesProvider');
	}
	return context;
};

const extractedEntityTypeOptionsList = extractedEntityTypeOptions();

const allEntityTypes = { label: 'All Entity Types', value: extractedEntityTypeOptionsList.map((option) => option.value) };

const Provider = ({ children }) => {
	const [getDatahubApi] = useApiLookup();
	const [selectedEntities, setSelectedEntities] = useState([]);
	const [openParentDialog, setOpenParentDialog] = useState(false);
	const [entities, setEntities] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [isLoadingHideShow, setIsLoadingHideShow] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [totalCount, setTotalCount] = useState(null);
	const [orderBy, setOrderBy] = useState(null);
	const [order, setOrder] = useState('asc');
	const [offset, setOffset] = useState(0);
	const [limit] = useState(10);
	const [entityTypes] = useState([
		allEntityTypes,
		...extractedEntityTypeOptionsList.map((entity) => ({ ...entity, value: [entity.value] }))
	]);
	const [selectedEntityType, setSelectedEntityType] = useState(entityTypes[0]);
	const [socialNetworks, setSocialNetworks] = useState([]);
	const [resetToken] = useCancelToken();

	const hasMounted = useRef(false);

	useEffect(() => {
		// Handles searching when order or orderBy changes
		// note-1
		if (!hasMounted.current) {
			return;
		}

		handleSearch();
	}, [orderBy, order]);

	useEffect(() => {
		if (!arrayIsNullOrEmpty(socialNetworks)) {
			return;
		}

		getSocialNetworks(getDatahubApi).then((data) => {
			const socialNetworks = {};

			if (arrayIsNullOrEmpty(data)) {
				setSocialNetworks([]);
			}

			data.forEach((network) => {
				Object.assign(socialNetworks, { [network.social_network_id]: network });
			});

			setSocialNetworks(socialNetworks);
		});
	}, []);

	useEffect(() => {
		if (!hasMounted.current) {
			hasMounted.current = true;
		}
	}, []);

	const resetState = (message) => {
		setErrorMessage(message);
		setEntities([]);
		setTotalCount(null);
		setOffset(0);
	};

	const handleSearch = async (offset = 0) => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);

		if (((orderBy !== null && offset === null) || (orderBy === null && order !== 'asc')) && offset === null) {
			// This essentially sets the order and orderBy back to default when searching from the search bar in index
			// This kicks off the use effect above see // note-1
			setOrder('asc');
			setOrderBy(null);
		} else {
			await search(offset || 0);
		}

		setIsLoading(false);
	};

	const search = async (offset = 0) => {
		try {
			if (!offset) {
				setTotalCount(null);
			}

			setErrorMessage(null);

			const { message, items, totalCount } = await searchEntities(
				getDatahubApi,
				searchValue,
				stringFilterComparisonType.contains,
				selectedEntityType.value,
				offset,
				limit,
				orderBy,
				order,
				resetToken
			);

			if (!isNullOrUndefined(message)) {
				resetState(message);
				return;
			}

			setTotalCount(totalCount === 0 ? null : totalCount);

			if (offset === 0) {
				setEntities(items);
			} else {
				const newItems = Array.from(entities || []);
				newItems.push(...items);
				setEntities(newItems);
			}

			setOffset(offset);
		} catch (e) {
			resetState();
		}
	};

	return (
		<EntitiesProvider.Provider
			value={{
				resetState,
				search,
				handleSearch,
				searchValue,
				setSearchValue,
				isLoading,
				setIsLoading,
				isLoadingMore,
				setIsLoadingMore,
				errorMessage,
				setErrorMessage,
				entities,
				setEntities,
				totalCount,
				setTotalCount,
				offset,
				setOffset,
				limit,
				entityTypes,
				selectedEntityType,
				setSelectedEntityType,
				openParentDialog,
				setOpenParentDialog,
				selectedEntities,
				setSelectedEntities,
				isLoadingHideShow,
				setIsLoadingHideShow,
				socialNetworks,
				orderBy,
				setOrderBy,
				order,
				setOrder
			}}
		>
			{children}
		</EntitiesProvider.Provider>
	);
};

export default Provider;
