import TextField from '@truescope-web/react/lib/components/form/TextField';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { workspaceStatesLookup } from '@/constants';
import { lockAdornment } from '../constants';

const Details = ({ workspace }) => {
	return (
		<Grid container>
			<Grid item>
				<Typography variant="h4">Workspace Details</Typography>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField labelAbove label="Workspace Name" value={workspace.name} readOnly />
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					labelAbove
					label="Status"
					value={workspace.workspace_state_id === workspaceStatesLookup.active ? 'Active' : 'Inactive'}
					disabled
					adornment={lockAdornment}
				/>
			</Grid>
		</Grid>
	);
};

export default Details;
