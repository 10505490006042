import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import Add from '@mui/icons-material/Add';
import Button from '@truescope-web/react/lib/components/form/Button';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Tabs from '@truescope-web/react/lib/components/layout/Tabs';
import Header from '../../../../components/Header';
import { feedNamesLookup } from '../constants';
import RulesTab from './RulesTab';

const Rules = ({ feedId }) => {
	const [dataTriggerGuid, setDataTriggerGuid] = useState(null);
	const navigate = useNavigate();

	const tabItems = [
		{
			navKey: 'active',
			label: 'Active',
			component: RulesTab,
			props: {
				dataTriggerGuid,
				isEnabled: true,
				feedId,
				refreshTabs: () => {
					setDataTriggerGuid(uuidv4());
				}
			}
		},
		{
			navKey: 'inactive',
			label: 'Inactive',
			component: RulesTab,
			props: {
				dataTriggerGuid,
				isEnabled: false,
				feedId,
				refreshTabs: () => {
					setDataTriggerGuid(uuidv4());
				}
			}
		}
	];

	const handleCreateFeedRule = (_e) => {
		navigate('/ingest/twitter/rules/create');
	};

	return (
		<Content>
			<Header header={`${feedNamesLookup[feedId]} Rules`}>
				<Button fab={<Add />} variant="primary" onClick={handleCreateFeedRule} aria-label="Create"></Button>
			</Header>
			<Grid container>
				<Grid item>
					<Tabs items={tabItems} />
				</Grid>
			</Grid>
		</Content>
	);
};

export default Rules;
