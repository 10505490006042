import ImageUploadCropper from '@truescope-web/react/lib/components/form/ImageUploadCropper';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';

const UserImage = ({ picture, onChange, onUpload }) => {
	const handleChange = (event) => {
		if (isNullOrUndefined(event)) {
			return;
		}
		onChange((prev) => ({ ...prev, pictureData: event.data }));
	};

	const handleUpload = async (event) => {
		return await onUpload(event.data);
	};

	return (
		<Inline horizontalAlignment={horizontalAlignment.center}>
			<ImageUploadCropper
				alt="User Avatar"
				viewMode={2}
				url={picture || ''}
				roundedCropper
				onUpload={handleUpload}
				onChange={handleChange}
			/>
		</Inline>
	);
};

export default UserImage;
