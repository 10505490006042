import { useCallback, useEffect, useState } from 'react';
import AsyncSelect from '@truescope-web/react/lib/components/form/AsyncSelect';
import useGeographiesApi from './useGeographiesApi';

const CountriesSelect = ({ countries, onChange, ...selectProps }) => {
	const geographiesApi = useGeographiesApi();
	const [defaultCountries, setDefaultCountries] = useState(undefined);

	useEffect(() => {
		geographiesApi?.getCountries().then((newCountries) => setDefaultCountries(newCountries));
	}, [setDefaultCountries, geographiesApi?.getCountries]);

	const handleLoadCountries = useCallback(
		(input) => geographiesApi?.getCountries({ filterValue: input }),
		[geographiesApi?.getCountries]
	);

	return (
		<AsyncSelect
			labelAbove
			isMulti
			label="Countries"
			onLoadOptions={handleLoadCountries}
			defaultOptions={defaultCountries}
			value={countries}
			placeholder="Countries"
			onChange={(e, value, rawValue) => onChange(e, value, rawValue, false)}
			menuPosition="fixed"
			{...selectProps}
		/>
	);
};

export default CountriesSelect;
