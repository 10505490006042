import CustomStore from 'devextreme/data/custom_store';

import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import formatDate from '@truescope/utils/lib/dates/formatDate';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import cleanString from '@truescope/utils/lib/strings/cleanString';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';

export const createEmptyDataSource = () => {
	return {
		store: new CustomStore({
			load: (_loadOptions) => {
				return {
					data: [],
					totalCount: 0,
					elasticQuery: null
				};
			}
		})
	};
};

/**
 * converts dx grid filters into datahub filters
 * @param {*} gridFilter
 * @param {*} existingFilter
 */
export const createWhereClauses = (gridFilter) => {
	if (arrayIsNullOrEmpty(gridFilter)) {
		return [];
	}

	if (gridFilter.some((child) => Array.isArray(child))) {
		const nestedGridFilters = gridFilter.filter((child) => Array.isArray(child));
		return nestedGridFilters.reduce((aggregate, nestedGridFilter) => {
			return aggregate.concat(createWhereClauses(nestedGridFilter));
		}, []);
	}

	const [fieldName, dxCondition, value] = gridFilter;
	return [createWhereClause(dxCondition, value, fieldName)];
};

const createWhereClause = (dxCondition, value, fieldName) => {
	let comparison_operator;

	switch (dxCondition) {
		case '=':
			comparison_operator = 'ILIKE';
			break;
		case 'contains':
			comparison_operator = 'ILIKE';
			value = `%${value}%`;
			break;
		case 'startswith':
			comparison_operator = 'ILIKE';
			value = `${value}%`;
			break;
		case 'endswith':
			comparison_operator = 'ILIKE';
			value = `%${value}`;
			break;
		case '<>':
			comparison_operator = 'NOT ILIKE';
			break;
		case 'notcontains':
			comparison_operator = 'NOT ILIKE';
			value = `%${value}%`;
			break;
		default:
			console.error(`dx search condition not supported (func name) '${dxCondition}'`);
			return null;
	}

	return {
		field_name: fieldName,
		comparison_operator,
		field_value: value
	};
};

export const createDataSource = (params) => {
	const {
		getDatahubApi,
		config = undefined,
		requestPath,
		convertItems = undefined,
		onSearchStart = undefined,
		onSearchComplete = undefined,
		fields = [],
		filterInclusions = undefined,
		useWhereClauses = false,
		key = undefined,
		requestParams = {}
	} = params;

	let { queryString = undefined } = params;

	const cache = {
		data: null,
		totalCount: 0,
		elasticQuery: null,
		filter: null
	};

	const additionalWhereClauses = requestParams?.whereClauses || [];

	const ds = {
		cache,
		store: new CustomStore({
			key: key,
			load: (loadOptions) => {
				const options = {
					offset: loadOptions.skip,
					limit: loadOptions.take,
					include: isNullOrUndefined(cache.filters) ? filterInclusions : undefined,
					...requestParams
				};

				if (useWhereClauses) {
					options.whereClauses = createWhereClauses(loadOptions.filter).concat(additionalWhereClauses);
				}

				queryString = cleanString(queryString);
				if (!arrayIsNullOrEmpty(fields)) {
					options.text_queries = [
						{
							fields,
							condition: 'must',
							value: !stringIsNullOrEmpty(queryString) ? queryString : '*'
						}
					];
				} else {
					options.queryText = !stringIsNullOrEmpty(queryString) ? queryString : undefined;
				}

				if (!arrayIsNullOrEmpty(loadOptions.sort)) {
					options.sort = loadOptions.sort[0].selector;
					options.desc = Boolean(loadOptions.sort[0].desc);
				}

				if (onSearchStart) {
					onSearchStart(options);
				}

				return getDatahubApi()
					.then((api) => api.post(requestPath, options))
					.then(({ data }) => {
						const searchResults = {
							data: convertItems ? convertItems(data.items, config) : data.items,
							totalCount: data.totalCount,
							elasticQuery: data.elasticQuery
						};

						if (data.values) {
							cache.filters = Object.keys(data.values).reduce((acc, curr) => {
								acc[curr] = { store: data.values[curr] };
								return acc;
							}, {});
						}

						return searchResults;
					})
					.catch((ex) => {
						console.error('failed search', ex);
						return {
							data: [],
							totalCount: 0,
							elasticQuery: null
						};
					});
			},
			update: (key, value) => {
				const index = cache.data.findIndex((datum) => datum[key] === value[key]);
				if (index < 0) {
					throw new Error(`cannot find item with ${key}=${value[key]}`);
				}
				cache.data[index] = value;
				return {
					...cache,
					data: [...cache.data]
				};
			},
			onLoaded: (e) => {
				cache.data = e.data;
				cache.totalCount = e.totalCount;
				cache.elasticQuery = e.elasticQuery;
				if (onSearchComplete) {
					onSearchComplete(e);
				}
			}
		})
	};

	return ds;
};

/**
 * Will render a simple text cell
 * @param {string}  value
 */
export const renderPlaintextCell = ({ value }) => {
	return <div className="wrap">{value}</div>;
};

/**
 * Will render the Audit Information 'Updated By'
 * @param {*} value the string value to be rendered
 */
export const renderAuditUpdatedByCell = ({ value }) => {
	return (
		<div className="wrap">
			<Typography>{value}</Typography>
		</div>
	);
};

/**
 * Will render the Audit Information 'Date Updated'
 * @param {*} data the item row with the pre-calculated date string
 */
export const renderAuditDateCell = ({ data }) => {
	return (
		<div className="wrap">
			<Typography>{data.date_updated_string}</Typography>
		</div>
	);
};

/**
 * renders an options cell for dx grid
 */
export const renderOptionsCell = ({ data }, handleOpenMenu, shouldIgnoreFunc, disabled = false) => {
	if (shouldIgnoreFunc && shouldIgnoreFunc(data)) {
		return null;
	}

	return (
		<div className="wrap">
			<IconButton aria-controls="row-menu" aria-haspopup="true" disabled={disabled} onClick={(e) => handleOpenMenu(e, data)}>
				<MoreVert />
			</IconButton>
		</div>
	);
};

/**
 * expands or collapses a row
 * @param {*} key
 * @param {*} component
 */
export const toggleExpandRow = (key, component) => {
	if (component.isRowExpanded(key)) {
		component.collapseRow(key);
	} else {
		component.expandRow(key);
	}
};

export const formatPagingInfoText = (e) => {
	const totalRecords = e.component.totalCount();
	const totalPages = e.component.pageCount();
	e.component.option('pager.infoText', `Page {0} of ${totalPages.toLocaleString()} (${totalRecords.toLocaleString()} items)`);
};

/**
 * renders the 'name' node of a component
 */
export const renderNameTreeNode = ({ key, data, value, component, row }, { canViewItem = (_e) => true, viewItem = null }) => {
	let content;
	if (!isNullOrUndefined(row.node) && row.node.hasChildren) {
		content = (
			<Typography variant="row-group">
				<Link className="clickable treenode-parent" onClick={() => toggleExpandRow(key, component)}>
					{value}
				</Link>
			</Typography>
		);
	} else if (canViewItem(data)) {
		content = (
			<Typography variant="row-link">
				<Link onClick={() => viewItem(data)}>{value}</Link>
			</Typography>
		);
	} else {
		content = <Typography variant="row-group">{value}</Typography>;
	}

	return <div className="wrap">{content}</div>;
};

/**
 * appends audit info to an item
 * @param {*} item
 */
export const appendAuditInfo = ({ user_context, ...item }) => ({
	...item,
	updated_by: !isNullOrUndefined(user_context) ? user_context.name : null,
	...formatDate('date_updated', item)
});

export const convertItems = (items, idProperty) =>
	items.map((item) => {
		const convertedItem = appendAuditInfo(item);

		if (!stringIsNullOrEmpty(idProperty) && isNullOrUndefined(item.id)) {
			convertedItem.id = item[idProperty];
		}

		return convertedItem;
	});

export const renderEmailCell = ({ value }) => {
	return (
		<div className="wrap">
			<Typography noWrap>{value}</Typography>
		</div>
	);
};
