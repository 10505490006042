import moment from 'moment';
import groupBy from '@truescope/utils/lib/arrays/groupBy';
import getDates from '@truescope/utils/lib/dates/getDates';
import getMonthName from '@truescope/utils/lib/dates/getMonthName';

export const createFilterConfig = (config) => {
	//build the filters config

	return {
		filterFieldOptions: config.media_item_fields.map((metadata) => ({
			label: metadata.display_name,
			value: metadata.field_name,
			metadata
		})),

		filterFunctionOptions: config.filter_functions.map((metadata) => ({
			label: metadata.display_name,
			value: metadata.function_name,
			metadata
		})),

		filterValueOptions: config.function_options.reduce((acc, cur) => {
			acc[cur.option_id] = cur.options;
			return acc;
		}, {}),

		functionParameters: groupBy(config.function_parameters, 'function_name')
	};
};

export const createMediaItemTableConfig = () => {
	//media item table config
	return {
		filters: {
			publicationDates: {
				store: getDates(new Date('2019-07-01T00:00:00.000Z'), new Date(), true),

				map: (value) => ({
					text: moment(value).format('DD'),
					value
				}),

				group: [(value) => value.getFullYear(), (value) => value.getMonth()],

				postProcess: (yearGroups) => {
					yearGroups.forEach((yearGroup) => {
						yearGroup.text = yearGroup.key + '';
						yearGroup.items.forEach((monthGroup) => {
							monthGroup.text = getMonthName(monthGroup.key);
						});
						yearGroup.items = yearGroup.items.sort((x, y) => x - y);
					});
					return yearGroups;
				}
			}
		}
	};
};
