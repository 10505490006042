import DataGrid, { Column, FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { CircularProgress, Link } from '@mui/material';
import Notification from '@truescope-web/react/lib/components/layout/Notification';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import { workspaceStatesLookup } from '@/constants';
import { extractError } from '../../../components/Api';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import MoreOptionsMenu from '../../../components/Widgets/MoreOptionsMenu';
import { removeScopeFromWorkspace } from '../../Clients/Workspaces/Workspace/Scopes/api';
import { createScopeWorkspacesTableDataSource } from './ScopeWorkspacesTableConstants';

const ScopeWorkspacesTable = ({ scopeWorkspaces, setScopeWorkspaces }) => {
	const [getDatahubApi] = useApiLookup();
	const dataSource = useMemo(() => createScopeWorkspacesTableDataSource(scopeWorkspaces), [scopeWorkspaces][getDatahubApi]);
	const navigate = useNavigate();
	const { showSnackbar } = useSnackbar();
	const [loadingWorkspaceId, setLoadingWorkspaceId] = useState(null);

	const handleRemoveClick = async (workspace) => {
		setLoadingWorkspaceId(workspace.workspace_id);
		try {
			await removeScopeFromWorkspace(getDatahubApi, workspace.workspace_id, workspace.workspace_scope_id);
			setScopeWorkspaces((prev) => prev.filter((scope) => scope.workspace_scope_id !== workspace.workspace_scope_id));
		} catch (e) {
			const errorMessage = extractError(e);
			showSnackbar(errorMessage, snackbarVariants.error);
		} finally {
			setLoadingWorkspaceId(null);
		}
	};

	const handleEditClick = (workspace) => {
		navigate(`/clients/workspaces/${workspace.workspace_id}`);
	};

	const renderMoreOptionsCell = ({ data: workspace }) => {
		return (
			<div className="wrap">
				{loadingWorkspaceId === workspace.workspace_id ? (
					<CircularProgress size={20} />
				) : (
					<MoreOptionsMenu
						disabled={!isNullOrUndefined(loadingWorkspaceId)}
						options={[
							{
								label: 'Edit',
								onClick: () => handleEditClick(workspace)
							},
							{
								label: 'Remove',
								onClick: () => handleRemoveClick(workspace)
							}
						]}
						size="xs"
					/>
				)}
			</div>
		);
	};

	const renderStatusCell = ({ value }) => {
		const isActive = value === workspaceStatesLookup.active;

		return (
			<div className="wrap">
				<Notification hideIcon center variant={isActive ? 'info' : 'warning'}>
					{isActive ? 'Active' : 'Inactive'}
				</Notification>
			</div>
		);
	};

	const renderWorkspaceCell = ({ data: workspace }) => {
		return (
			<div className="wrap">
				<Typography variant="row-link">
					<Link onClick={() => handleEditClick(workspace)}>{workspace.workspace_name}</Link>
				</Typography>
			</div>
		);
	};

	return (
		<>
			<DataGrid id="scope-workspaces-table" dataSource={dataSource}>
				<FilterRow visible applyFilter="auto" />
				<HeaderFilter visible />
				<Column
					caption="Workspaces"
					cssClass="cell cell--pad clickable"
					dataField="workspace_name"
					cellRender={renderWorkspaceCell}
				>
					<HeaderFilter allowSearch />
				</Column>
				<Column
					caption="Status"
					dataField="workspace_state_id"
					cssClass="cell cell--pad clickable"
					cellRender={renderStatusCell}
					alignment="left"
					allowSorting
					allowFiltering={false}
					allowSearch={false}
				/>
				<Column
					width={60}
					caption=""
					cssClass="cell cell--center"
					alignment="center"
					allowFiltering={false}
					allowSearch={false}
					allowSorting={false}
					cellRender={renderMoreOptionsCell}
				/>
			</DataGrid>
		</>
	);
};

export default ScopeWorkspacesTable;
