import TreeList, {
	Column,
	ColumnChooser,
	FilterRow,
	HeaderFilter,
	Pager,
	Paging,
	Selection,
	StateStoring
} from 'devextreme-react/tree-list';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import Notification from '@truescope-web/react/lib/components/layout/Notification';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { workspaceStatesLookup } from '@/constants';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { useConfig } from '../../../components/ConfigProvider';
import { createDataSource, renderNameTreeNode } from '../../../components/Dx/DxGridConstants';
import { convertItems, statusHeaderFilter } from './constants';

const WorkspacesTable = () => {
	const [getDatahubApi] = useApiLookup();
	const { config } = useConfig();
	const navigate = useNavigate();
	const treeList = useRef(null);
	const dataSource = createDataSource({ getDatahubApi, config, convertItems, requestPath: '/workspaces/v1' });

	const view = ({ workspace_id }) => {
		navigate(`/clients/workspaces/${workspace_id}`);
	};

	const renderStatusCell = ({ value }) => {
		const isActive = value === workspaceStatesLookup.active;

		return (
			<div className="wrap">
				<Notification hideIcon center variant={isActive ? 'info' : 'warning'}>
					{isActive ? 'Active' : 'Inactive'}
				</Notification>
			</div>
		);
	};

	const renderTextCell = ({ value }) => {
		return (
			<div className="wrap">
				<Typography variant="subtitle" noWrap>
					{value}
				</Typography>
			</div>
		);
	};

	const renderSeatsCell = ({ value, data }) => {
		if (data.type !== 'workspace') {
			return null;
		}

		return (
			<div className="wrap">
				<Typography variant="subtitle" noWrap>
					{value} of {data.max_seats}
				</Typography>
			</div>
		);
	};

	const renderDateCell = ({ data }) => {
		return (
			<div className="wrap">
				<Typography>{data.plan_end_date_string}</Typography>
			</div>
		);
	};

	return (
		<div className="workspaces-table">
			<TreeList
				autoExpandAll={false}
				ref={treeList}
				dataSource={dataSource}
				showRowLines={true}
				showColumnLines={false}
				showBorders={true}
				dataStructure="plain"
				keyExpr="key"
				wordWrapEnabled={true}
				id="workspaces"
			>
				<FilterRow visible={true} applyFilter="auto" />
				<StateStoring enabled={true} type="localStorage" storageKey="workspaceTableStorage" />
				<HeaderFilter visible={true} />
				<Selection mode="single" allowSelectAll={false} />
				<ColumnChooser enabled={false} />

				<Column
					dataField="name"
					caption="Workspace"
					dataType="string"
					alignment="left"
					cellRender={(e) =>
						renderNameTreeNode(e, {
							canViewItem: ({ type }) => type === 'workspace',
							viewItem: view
						})
					}
					allowFiltering
					allowSearch
					allowSorting
				/>

				<Column
					dataField="client_name"
					caption="Client"
					dataType="string"
					alignment="left"
					cellRender={renderTextCell}
					allowFiltering
					allowSearch
					allowSorting
				/>

				<Column
					dataField="market"
					caption="Market"
					dataType="string"
					alignment="left"
					cellRender={renderTextCell}
					allowFiltering
					allowSearch
					allowSorting
				/>

				<Column
					dataField="workspace_state_id"
					caption="Status"
					width={120}
					cellRender={renderStatusCell}
					allowFiltering
					allowSearch={false}
					allowSorting
				>
					<HeaderFilter dataSource={statusHeaderFilter} />
				</Column>

				<Column dataField="user_count" caption="Seats" dataType="number" cellRender={renderSeatsCell} />

				<Column alignment="right" dataField="plan_end_date" caption="End Date" dataType="date" cellRender={renderDateCell} />

				<Paging defaultPageSize={10} />

				<Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} />
			</TreeList>
		</div>
	);
};

export default WorkspacesTable;
