/**
 * workspace state
 */
export const workspaceStatesLookup = {
	inactive: 0,
	active: 1
};

/**
 * workspace user state
 */
export const workspaceUserStatesLookup = {
	active: 1,
	inactive: 2
};
