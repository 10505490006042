import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import View from '@truescope-web/react/lib/components/layout/View';

const ExternalError = ({ errorMessage }) => {
	return (
		<View>
			<Content>
				<Grid container>
					<Grid item>
						<Typography variant="h1">Datahub failed to load</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h1">Reason: {errorMessage}</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h4">Please contact the truescope support team at support@truescope.com</Typography>
					</Grid>
				</Grid>
			</Content>
		</View>
	);
};

export default ExternalError;
