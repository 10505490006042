import TableCell from '@mui/material/TableCell';
import MUITableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import { useEntities } from './provider';

const headCells = [
	{ id: 'checkbox', numeric: false, label: '', sortable: true },
	{ id: 'wiki', numeric: false, label: '', sortable: true },
	{ id: 'name', numeric: false, label: 'Entity Name', sortable: true },
	{ id: 'type', numeric: false, label: 'Entity Type', sortable: true },
	{ id: 'aliasesCount', numeric: true, label: 'Aliases', sortable: true },
	{ id: 'averageItemsDay', numeric: true, label: 'Avg items per day', sortable: false },
	{ id: 'menu', numeric: false, label: '', sortable: true }
];

const TableHead = () => {
	const { entities, orderBy, setOrderBy, order, setOrder } = useEntities();

	const noResults = arrayIsNullOrEmpty(entities);
	if (noResults) {
		return <></>;
	}

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	return (
		<MUITableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						className={`table-head-${headCell.id}`}
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{headCell.sortable ? (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={() => handleRequestSort(headCell.id)}
							>
								{headCell.label}
							</TableSortLabel>
						) : (
							headCell.label
						)}
					</TableCell>
				))}
			</TableRow>
		</MUITableHead>
	);
};

export default TableHead;
