import cloneDeep from 'lodash.clonedeep';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import Add from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Button from '@truescope-web/react/lib/components/form/Button';
import Select from '@truescope-web/react/lib/components/form/Select';
import Switch from '@truescope-web/react/lib/components/form/Switch';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { useConfig } from '../../../components/ConfigProvider';
import MediaItemTable from '../../../components/Table/MediaItemTable';
import { createElasticSearchPreview } from './QueryConditionGroupConstants';
import JsonViewer from './QueryConditionGroupsBuilder/JsonViewer';
import QueryConditionGroupsBuilder from './QueryConditionGroupsBuilder/QueryConditionGroupsBuilder';
import { saveScope } from './ScopeConstants';
import { updateScopeState } from './ScopeReducer';

const ScopeDetailsTable = ({ create, scope, scopeDispatch, scopeOptions }) => {
	const [getDatahubApi] = useApiLookup();
	const { config } = useConfig();
	const { showSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [isMediaItemTableLoading, setIsMediaItemTableLoading] = useState(false);
	const [isPartialLoading, setIsPartialLoading] = useState(false);
	const [searchDataTrigger, setSearchDataTrigger] = useState(null);
	const disableButtons = isMediaItemTableLoading && isPartialLoading;
	const scopeOptionsDisabled = arrayIsNullOrEmpty(scopeOptions);

	const handleSearch = () => {
		try {
			setSearchDataTrigger({
				errorMessage: null,
				trigger: uuidv4(),
				...createElasticSearchPreview(scope.queryConditionGroups, config.searchFieldVariants)
			});
		} catch (e) {
			setSearchDataTrigger({
				errorMessage: e.message
			});
		}
	};

	const handleAddFilterGroup = () => {
		scopeDispatch(
			updateScopeState({
				...scope,
				queryConditionGroups: [...scope.queryConditionGroups].concat({
					id: Math.max(...scope.queryConditionGroups.map(({ id }) => id)) + 1,
					groupOperator: 'must',
					conditionsOperator: 'should',
					conditions: [
						{
							id: 1,
							field: null,
							value: null,
							filterFunction: null
						}
					]
				})
			})
		);
	};

	const handleCancel = () => {
		navigate('/media/feeds');
	};

	const handleSave = async () => {
		try {
			setIsPartialLoading(true);
			const clonedScope = cloneDeep(scope);
			await saveScope(getDatahubApi, clonedScope, config.searchFieldVariants);
			navigate('/media/feeds');
		} catch (e) {
			showSnackbar(e.message, snackbarVariants.error);
		} finally {
			setIsPartialLoading(false);
		}
	};

	const handleQueryConditionGroupsChange = (queryConditionGroups) => {
		scopeDispatch(
			updateScopeState({
				queryConditionGroups
			})
		);
	};

	return (
		<Grid container>
			<Grid item sm={12}>
				<Inline>
					<TextField
						labelAbove
						label="Feed Name"
						placeholder="enter a name"
						value={scope.name}
						onChange={(_e, value) => scopeDispatch(updateScopeState({ name: value }))}
					/>
					<Select
						labelAbove
						label="Parent"
						disabled={scopeOptionsDisabled}
						placeholder={scopeOptionsDisabled ? 'No feeds created' : 'select a parent feed'}
						options={scopeOptions}
						value={scope.parent_scope_id}
						onChange={(_e, value) => scopeDispatch(updateScopeState({ parent_scope_id: value }))}
					/>
				</Inline>
			</Grid>
			<Grid item>
				<Typography variant="subtitle">Feed Options</Typography>
			</Grid>
			<Grid item>
				<Inline>
					<Switch
						label="Match Items"
						value={scope.enable_item_matching}
						onChange={(e) => scopeDispatch(updateScopeState({ enable_item_matching: Boolean(e.target.checked) }))}
					/>
				</Inline>
			</Grid>
			{scope.enable_item_matching && (
				<Grid item>
					<QueryConditionGroupsBuilder
						queryConditionGroups={scope.queryConditionGroups}
						onChange={handleQueryConditionGroupsChange}
					/>
				</Grid>
			)}
			<Grid item sm={6}>
				<Inline>
					{scope.enable_item_matching && (
						<Button tooltip="Add filter group" fab={<Add />} disabled={disableButtons} onClick={handleAddFilterGroup}></Button>
					)}
				</Inline>
			</Grid>
			<Grid item sm={6}>
				<Inline horizontalAlignment={horizontalAlignment.right}>
					<Button disabled={disableButtons} variant="secondary" onClick={handleCancel}>
						Cancel
					</Button>
					<Button disabled={disableButtons} loading={isPartialLoading} onClick={() => handleSave()}>
						{create ? 'Create' : 'Apply'}
					</Button>
					<Button disabled={disableButtons || !scope.enable_item_matching} onClick={handleSearch} aria-label="Media search">
						Search
					</Button>
				</Inline>
			</Grid>
			{!isNullOrUndefined(searchDataTrigger?.trigger) && (
				<Grid item>
					<Paper elevation={0}>
						<MediaItemTable
							dataTriggerGuid={searchDataTrigger.trigger}
							setIsLoading={setIsMediaItemTableLoading}
							requestParams={{
								sort: 'item_date',
								desc: true,
								elasticQuery: searchDataTrigger.elasticSearchPreview
							}}
						/>
					</Paper>
				</Grid>
			)}
			{!stringIsNullOrEmpty(searchDataTrigger?.errorMessage) && (
				<Grid item>
					<Alert severity="error">{searchDataTrigger?.errorMessage}</Alert>
				</Grid>
			)}
			{!isNullOrUndefined(searchDataTrigger?.elasticQueryBool) && (
				<Grid item>
					<JsonViewer object={searchDataTrigger.elasticQueryBool} />
				</Grid>
			)}
			<Grid item></Grid>
		</Grid>
	);
};

export default ScopeDetailsTable;
