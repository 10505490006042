import { createTheme } from '@mui/material/styles';

/*
override some of the deeply nested global MUI Theme settings
*/

const tsblue = {
	50: '#99bdef',
	100: '#6ca0e9',
	200: '#4b8be4',
	300: '#3279dd',
	400: '#1d6ad6',
	500: '#0b5cce',
	600: '#0b55bc',
	700: '#0b4ca8',
	800: '#0b4598',
	900: '#0a3f8a',
	A100: '#82b1ff',
	A200: '#448aff',
	A400: '#2979ff',
	A700: '#2962ff'
};

export default createTheme({
	typography: {
		fontFamily: `Roboto, 'IBM Plex Sans', sans-serif`
	},
	palette: {
		primary: tsblue,
		text: {
			primary: '#2b4265',
			secondary: '#8493A6'
		}
	},
	components: {
		MuiLink: {
			defaultProps: {
				underline: 'none'
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					//we use uppercase in datahub
					//textTransform: 'none'
				},
				startIcon: {
					marginRight: '4px',
					marginLeft: '0'
				}
			}
		}
	}
});
