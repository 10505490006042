import DataGrid, { Column, FilterRow, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { useCallback, useMemo, useRef, useState } from 'react';
import { BiGhost } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import { CircularProgress, Tooltip } from '@mui/material';
import Link from '@mui/material/Link';
import Notification from '@truescope-web/react/lib/components/layout/Notification';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Alert from '@truescope-web/react/lib/components/modal/Alert';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import { workspaceUserStatesLookup } from '@/constants';
import { extractError } from '../../../../../components/Api';
import { useApiLookup } from '../../../../../components/ApiLookupProvider';
import { useConfig } from '../../../../../components/ConfigProvider';
import { formatPagingInfoText } from '../../../../../components/Dx/DxGridConstants';
import { getAuthState } from '../../../../../components/GoogleAuth/constants';
import MoreOptionsMenu from '../../../../../components/Widgets/MoreOptionsMenu';
import { resetClientUserPassword } from '../../../ClientUser/constants';
import { appUrl, canImpersonateClientUser, createImpersonation, deleteImpersonation } from '../../../ClientUserConstants';
import { setUserState } from './api';
import { createUsersDataSource } from './constants';

const UsersTable = ({ workspaceUsers, setWorkspaceUsers, workspacePlan, workspaceId }) => {
	const [getDatahubApi] = useApiLookup();
	const navigate = useNavigate();
	const { showSnackbar } = useSnackbar();
	const { config } = useConfig();
	const dataSource = useMemo(() => createUsersDataSource(workspaceUsers, config.roleOptions), [workspaceUsers, config.roleOptions]);
	const [deactivationAlertState, setDeactivationAlertState] = useState(null);
	const { user } = getAuthState();
	const canImpersonate = canImpersonateClientUser(user.email);
	const dataGridRef = useRef(null);
	const [isLoadingRecordId, setIsLoadingRecordId] = useState(null);

	const handleOpenClientApp = () => {
		const newWindow = window.open(`https://${appUrl}/w/${workspaceId}/inbox`, '_blank');
		if (newWindow) {
			newWindow.opener = null;
		}
	};

	const handleCreateImpersonation = async (workspaceUser) => {
		setIsLoadingRecordId(workspaceUser.user_id);
		try {
			const { impersonation } = await createImpersonation(getDatahubApi, workspaceUser.user_id);
			setWorkspaceUsers((prev) => {
				const previouslyImpersonatedUser = prev.find((user) => user.impersonating_user_id === impersonation.source_client_user_id);
				if (!isNullOrUndefined(previouslyImpersonatedUser)) {
					delete previouslyImpersonatedUser.impersonating_user_id;
				}
				const currentlyImpersonatedUser = prev.find((user) => user.user_id === workspaceUser.user_id);
				if (!isNullOrUndefined(currentlyImpersonatedUser)) {
					currentlyImpersonatedUser.impersonating_user_id = impersonation.source_client_user_id;
				}
				return [...prev];
			});
			showSnackbar(`impersonation active`, snackbarVariants.success);
			handleOpenClientApp();
		} catch (e) {
			showSnackbar(extractError(e), snackbarVariants.error);
		} finally {
			setIsLoadingRecordId(null);
		}
	};

	const handleDeleteImpersonation = async (workspaceUser) => {
		setIsLoadingRecordId(workspaceUser.user_id);
		try {
			await deleteImpersonation(getDatahubApi, workspaceUser.user_id);
			setWorkspaceUsers((prev) => {
				const impersonatedUser = prev.find((user) => user.user_id === workspaceUser.user_id);
				delete impersonatedUser.impersonating_user_id;
				return [...prev];
			});
			showSnackbar(`impersonation cleared`, snackbarVariants.success);
		} catch (e) {
			showSnackbar(extractError(e), snackbarVariants.error);
		} finally {
			setIsLoadingRecordId(null);
		}
	};

	const handleToggleActivateUserClick = useCallback(
		async (workspaceUser) => {
			const updatedUserWorkspaceStateId =
				workspaceUser.workspace_user_state_id === workspaceUserStatesLookup.active
					? workspaceUserStatesLookup.inactive
					: workspaceUserStatesLookup.active;

			const actionName = updatedUserWorkspaceStateId === workspaceUserStatesLookup.inactive ? 'deactivate' : 'activate';

			try {
				if (updatedUserWorkspaceStateId === workspaceUserStatesLookup.active) {
					//if we're about to active a new user, make sure we actually have spaces to do so
					const activeUserCount = workspaceUsers.filter(
						(user) => user.workspace_user_state_id === workspaceUserStatesLookup.active
					).length;
					if (
						workspaceUser.workspace_user_state_id === workspaceUserStatesLookup.inactive &&
						activeUserCount >= workspacePlan.max_seats
					) {
						throw new Error('The maximum active user count has been reached for this workspace');
					}
				}

				dataGridRef.current._instance.beginCustomLoading();
				setIsLoadingRecordId(workspaceUser.user_id);

				const { message } = await setUserState(
					getDatahubApi,
					workspaceUser.workspace_user_id,
					updatedUserWorkspaceStateId,
					workspaceUser.user_id,
					parseInt(workspaceId)
				);

				if (!stringIsNullOrEmpty(message)) {
					throw new Error(message);
				}

				// This will update the user object on the page without reloading the page
				setWorkspaceUsers((prev) => {
					const index = prev.findIndex((user) => user.workspace_user_id === workspaceUser.workspace_user_id);
					prev[index] = { ...prev[index], workspace_user_state_id: updatedUserWorkspaceStateId };
					return [...prev];
				});
				showSnackbar(`User ${workspaceUser.email} has been successfully ${actionName}d`, snackbarVariants.success);
			} catch (e) {
				showSnackbar(`Failed to ${actionName} user.`, snackbarVariants.error);
			} finally {
				setIsLoadingRecordId(null);
				dataGridRef.current._instance.endCustomLoading();
			}
		},
		[workspaceUsers, setWorkspaceUsers]
	);

	const handleEditUserClick = (workspaceUser) => {
		view(workspaceUser);
	};

	const handleResetPasswordClick = async (workspaceUser) => {
		const { email, name } = workspaceUser;
		dataGridRef.current._instance.beginCustomLoading();
		setIsLoadingRecordId(workspaceUser.user_id);
		try {
			await resetClientUserPassword(email);
			showSnackbar(`Password Reset Sent to ${name} at ${email}`, snackbarVariants.success);
		} catch (e) {
			const msg = `Failed to issue password reset for ${name} - ${extractError(e)}`;
			console.error(msg, e);
			showSnackbar(msg, snackbarVariants.error);
		} finally {
			dataGridRef.current._instance.endCustomLoading();
			setIsLoadingRecordId(null);
		}
	};

	const handleShowDeactivateAlert = (workspaceUser) => {
		setDeactivationAlertState({
			isOpen: true,
			workspaceUser
		});
	};

	const handleDeactivateUser = () => {
		handleToggleActivateUserClick(deactivationAlertState.workspaceUser);
		setDeactivationAlertState(null);
	};

	const view = ({ user_id }) => {
		navigate(`/clients/users/${user_id}`);
	};

	const renderNameCell = ({ data, value }) => {
		const hasImpersonation = !isNullOrUndefined(data.impersonating_user_id);
		const areYouImpersonating = data.impersonating_user_id === config.clientUser?.user_id;
		return (
			<div className="wrap">
				<div className="user-workspaces-table__name-cell">
					<Link onClick={() => view(data)}>{value}</Link>
					{isLoadingRecordId === data.user_id && <CircularProgress size={16} />}
					{hasImpersonation && (
						<Tooltip
							title={
								areYouImpersonating
									? 'You are impersonating this user'
									: `Impersonation active with user ${data.impersonating_user_id}`
							}
						>
							<div className="user-workspaces-table__name-cell__impersonation-icon">
								<BiGhost />
							</div>
						</Tooltip>
					)}
				</div>
			</div>
		);
	};

	const renderStatusCell = ({ data, value }) => {
		return (
			<div className="wrap">
				<Notification hideIcon center variant={data.statusVariant}>
					{value}
				</Notification>
			</div>
		);
	};

	const renderRoleCell = ({ value }) => {
		return (
			<div className="wrap">
				<Typography>{value}</Typography>
			</div>
		);
	};

	const renderMoreOptionsCell = ({ data }) => {
		const hasImpersonation = !isNullOrUndefined(data.impersonating_user_id);
		const areYouImpersonating = data.impersonating_user_id === config.clientUser?.user_id;
		return (
			<div className="wrap">
				<MoreOptionsMenu
					options={[
						{
							label: 'Edit',
							onClick: () => handleEditUserClick(data)
						},
						data.workspace_user_state_id === 1
							? {
									label: 'Deactivate User',
									onClick: () => handleShowDeactivateAlert(data)
								}
							: {
									label: 'Activate User',
									onClick: () => handleToggleActivateUserClick(data)
								},
						{
							label: 'Issue Password Reset',
							onClick: () => handleResetPasswordClick(data)
						},
						areYouImpersonating
							? {
									label: 'Visit App',
									onClick: handleOpenClientApp,
									disabled: !canImpersonate
								}
							: {
									label: 'Log in as User',
									onClick: () => handleCreateImpersonation(data),
									disabled: !canImpersonate
								}
					].concat(
						canImpersonate
							? hasImpersonation
								? [
										{
											label: areYouImpersonating ? 'Log out as User' : 'Remove Impersonation',
											onClick: () => handleDeleteImpersonation(data),
											disabled: !canImpersonate
										}
									]
								: []
							: []
					)}
					disabled={!isNullOrUndefined(isLoadingRecordId)}
					size="xs"
				/>
			</div>
		);
	};

	const renderDataGrid = () => {
		if (dataSource.cache.totalCount === 0) {
			return renderEmptyDataGrid();
		}
		return (
			<DataGrid
				id="story-table"
				ref={dataGridRef}
				showRowLines={true}
				dataSource={dataSource}
				showColumnLines={false}
				showBorders={true}
				onContentReady={formatPagingInfoText}
			>
				<FilterRow visible={true} applyFilter="auto" />

				<HeaderFilter visible={true} />

				<Column caption="Name" dataField="name" cssClass="cell cell--pad clickable" cellRender={renderNameCell}>
					<HeaderFilter allowSearch={true} />
				</Column>

				<Column
					caption="Role"
					dataField="role"
					width={120}
					cssClass="cell cell--center"
					alignment="center"
					cellRender={renderRoleCell}
				/>

				<Column
					caption="Status"
					dataField="statusLabel"
					width={120}
					cssClass="cell cell--pad clickable"
					allowFiltering={false}
					allowSearch={false}
					cellRender={renderStatusCell}
				>
					<HeaderFilter allowSearch={true} />
				</Column>

				<Column
					dataField="id"
					width={60}
					cssClass="cell cell--center"
					alignment="center"
					caption=""
					cellRender={renderMoreOptionsCell}
					allowFiltering={false}
					allowSearch={false}
					allowSorting={false}
				/>

				<Paging defaultPageSize={10} />

				<Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} />
			</DataGrid>
		);
	};

	const renderEmptyDataGrid = () => {
		return <Typography variant="subtitle">There are no active users in this Workspace</Typography>;
	};

	return (
		<>
			{renderDataGrid()}
			<Alert
				title="Warning"
				renderContent={() => (
					<div>
						<Typography variant="subtitle">
							Deactivated user will lose all of their Dashboard, Report and Alert permissions.
						</Typography>
						<Typography variant="subtitle">Would you like to deactivate this user?</Typography>
					</div>
				)}
				cancelLabel="No"
				acceptLabel="Yes"
				open={deactivationAlertState?.isOpen}
				handleCancel={() => setDeactivationAlertState(null)}
				handleAccept={handleDeactivateUser}
			/>
		</>
	);
};

export default UsersTable;
