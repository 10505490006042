import { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SocialNetworkHandleEditor from '../../../components/Widgets/SocialNetworkHandleEditor';
import '../../../scss/views/Entities/EntitiesList/SocialHandles.scss';

const SocialHandles = ({ socialHandles, socialNetworks, sourceReference, sourceReferenceType }) => {
	const [metadata, onChange] = useState({
		social_network_handles: socialHandles,
		social_networks: Object.values(socialNetworks),
		source_reference: sourceReference,
		sourceReferenceType
	});

	return (
		<>
			<hr />
			<TableContainer>
				<Table id="social-handles" size="small">
					<TableHead>
						<TableRow>
							<TableCell>Social handles</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow>
							<TableCell>
								<SocialNetworkHandleEditor
									metadata={metadata}
									onChange={onChange}
									sourceReference={sourceReference}
									sourceReferenceType={sourceReferenceType}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default SocialHandles;
