import moment from 'moment/moment';
import Sentiment from '@truescope/utils/lib/mediaItems/Sentiment';

const mediaItemReducer = (state, action) => {
	switch (action?.type) {
		case 'update':
			return {
				...state,
				...action.payload
			};
		case 'reset':
			return getMediaItemInitialState(state);
		default:
			throw new Error(`unknown reducer action '${action?.type}'`);
	}
};

export const getMediaItemInitialState = (defaultProps) => {
	const now = moment();
	return {
		sentiment: Sentiment.neutral,
		title: undefined,
		body: undefined,
		summary: undefined,
		channel: undefined,
		date: now.format('YYYY-MM-DD'),
		time: now.format('HH:mm'),
		print_clip: undefined,
		source_url: undefined,
		author: undefined,
		...(defaultProps || {})
	};
};

export const updateMediaItemState = (data) => {
	return {
		type: 'update',
		payload: data
	};
};

export default mediaItemReducer;
