import { useCallback, useMemo, useState } from 'react';
import Accordion from '@truescope-web/react/lib/components/layout/Accordion';
import TablePanel from '@truescope-web/react/lib/components/layout/TablePanel';
import DebugView from '@truescope-web/react/lib/components/widgets/DebugView';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import ExtractedEntityType from '@truescope/utils/lib/mediaItems/ExtractedEntityType';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import { useConfig } from '../../../components/ConfigProvider';
import {
	annotationTableFields,
	audienceTableFields,
	createEmptyWorkspace,
	entityTypeLookup,
	getEntityTableFields,
	getUpdatedSelectOptions
} from './MediaItemConstants';
import QueriesPanel from './QueriesPanel';
import ScopesPanel from './ScopesPanel';

const MediaItemAccordian = ({ mediaItem, setMediaItem, setEntityId, setShowCompanySheet, setShowPersonSheet, updateItem }) => {
	const { config } = useConfig();
	const [isSaving, setIsSaving] = useState(false);

	const fillWorkspacesTriggerBy = useCallback(
		(document) => {
			const { workspaces, scopes } = document;

			const workspacesLookup = {};
			workspaces.forEach((workspace, index) => {
				workspacesLookup[workspace.workspace_id] = index;
			});

			for (const scope of scopes) {
				if (arrayIsNullOrEmpty(scope.workspaces)) {
					delete scope.workspaces;
					continue;
				}

				for (const scopeWorkspace of scope.workspaces) {
					let workspace = workspaces[workspacesLookup[scopeWorkspace.workspace_id]];

					if (scopeWorkspace.checked) {
						if (isNullOrUndefined(workspace)) {
							workspace = createEmptyWorkspace(scopeWorkspace.name, scopeWorkspace.workspace_id);
							workspaces.push(workspace);
							workspacesLookup[scopeWorkspace.workspace_id] = workspaces.length - 1;
						}

						const matchingScopeIdIndex = workspace.triggered_by.scope_ids.findIndex((id) => id === scope.scope_id);
						if (matchingScopeIdIndex === -1) {
							workspace.triggered_by.scope_ids.push(scope.scope_id);
							workspace.is_deleted = false;
						}
					}
				}

				delete scope.workspaces;
			}

			return document;
		},
		[createEmptyWorkspace]
	);

	const handleEntityClick = useCallback((entityId, entityTypeId) => {
		setEntityId(entityId);

		if (entityTypeId === ExtractedEntityType.company) {
			setShowCompanySheet(true);
		} else if (entityTypeId === ExtractedEntityType.person) {
			setShowPersonSheet(true);
		} else if (entityTypeId === ExtractedEntityType.location) {
			throw new Error('location not supported');
		}
	}, []);

	const handleOnScopesSave = useCallback(
		(scopes, showLoader = true) => {
			const newMediaItem = fillWorkspacesTriggerBy({ ...mediaItem, scopes });
			if (showLoader) {
				setIsSaving(true);
			}
			updateItem(newMediaItem)
				.then(() => setMediaItem((prev) => ({ ...prev, scopes: newMediaItem.scopes })))
				.catch((e) => console.error('Error saving scopes ', e))
				.finally(() => setIsSaving(false));
		},
		[mediaItem, fillWorkspacesTriggerBy, updateItem]
	);

	const handleOnQueriesSave = useCallback(
		(queries, showLoader = true) => {
			if (showLoader) {
				setIsSaving(true);
			}

			const newMediaItem = {
				...mediaItem,
				queries,
				workspaces: (mediaItem.workspaces || []).map((workspace) => ({
					...workspace,
					sentiment: isNullOrUndefined(workspace.sentiment) ? 'Neutral' : workspace.sentiment
				}))
			};

			const newQuery = (queries || []).find(
				(query) => !mediaItem.queries.some((mediaItemQuery) => mediaItemQuery.query_id === query.query_id)
			);

			if (
				!isNullOrUndefined(newQuery) &&
				!newMediaItem.workspaces.some((workspace) => workspace.workspace_id === newQuery.metadata.workspace_id)
			) {
				newMediaItem.workspaces = [
					...newMediaItem.workspaces,
					{
						...createEmptyWorkspace(newQuery.metadata.workspace_name, newQuery.metadata.workspace_id),
						sentiment: 'Neutral'
					}
				];
			}

			updateItem(newMediaItem)
				.then(() => setMediaItem((prev) => ({ ...prev, queries: queries, workspaces: newMediaItem.workspaces })))
				.catch((e) => console.error('Error saving scopes ', e))
				.finally(() => setIsSaving(false));
		},
		[mediaItem, setIsSaving, updateItem]
	);

	const getPanelItems = useCallback(() => {
		const panelItems = [];
		if ((mediaItem.audience_data || []).length > 0) {
			panelItems.push({ label: 'Audience', content: <TablePanel items={mediaItem.audience_data} fields={audienceTableFields} /> });
		}

		if ((mediaItem.annotations || []).length > 0) {
			panelItems.push({ label: 'Annotations', content: <TablePanel items={mediaItem.annotations} fields={annotationTableFields} /> });
		}

		panelItems.push({
			label: 'Feeds',
			content: (
				<ScopesPanel
					onSave={handleOnScopesSave}
					isLoading={isSaving}
					scopes={mediaItem?.scopes}
					config={config}
					selectOptions={getUpdatedSelectOptions(config.scopeOptions, mediaItem.scopes, entityTypeLookup.scope)}
				/>
			)
		});

		panelItems.push({
			label: 'Queries',
			content: (
				<QueriesPanel
					queries={mediaItem?.queries}
					onSave={handleOnQueriesSave}
					isLoading={isSaving}
					config={config}
					selectOptions={getUpdatedSelectOptions(config.queryOptions, mediaItem.queries, entityTypeLookup.query)}
				/>
			)
		});

		if ((mediaItem.extracted_entities || []).length > 0) {
			panelItems.push({
				label: 'Entities',
				content: <TablePanel items={mediaItem.extracted_entities} fields={getEntityTableFields(handleEntityClick)} />
			});
		}

		panelItems.push({ label: 'JSON', content: <DebugView object={mediaItem} /> });

		return panelItems;
	}, [mediaItem, handleOnScopesSave, getUpdatedSelectOptions, config, entityTypeLookup, getEntityTableFields, handleEntityClick]);

	const panelItems = useMemo(() => getPanelItems(), [getPanelItems, mediaItem]);
	const queriesPanelIndex = useMemo(() => panelItems.findIndex(({ label }) => label === 'Queries'), [panelItems]);

	return (
		<Accordion
			initialExpandedIndex={!arrayIsNullOrEmpty(panelItems) ? (queriesPanelIndex !== -1 ? queriesPanelIndex : 0) : -1}
			items={panelItems}
		/>
	);
};
export default MediaItemAccordian;
