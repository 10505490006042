import createApi from '@truescope/utils/lib/api/createApi';
import config from '../../../config.json';

export const resetClientUserPassword = async (email) => {
	const api = createApi(config.api.clientManagement.baseUrl);
	await api.post(`dbconnections/change_password`, {
		client_id: config.clientApp.clientId,
		email,
		connection: config.clientApp.connection
	});
};
