import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectFilter = ({ options = [], applyValue, item }) => {
	const handleChange = (_event, value) => {
		const newValue = value.props;
		applyValue({ ...item, value: newValue.value });
	};

	return (
		<FormControl fullWidth variant="standard">
			<InputLabel>Media Type</InputLabel>
			<Select value={[item.value]} onChange={handleChange}>
				{options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.text}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectFilter;
