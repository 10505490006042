import { useState } from 'react';
import Close from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import '../../../scss/views/Entities/EntitiesList/Aliases.scss';
import { removeParent } from './constants';

const headCells = [
	{ id: 'alias', alignRight: false, label: 'Alias' },
	{ id: 'total-items', alignRight: true, label: 'Total Items' },
	{ id: 'remove', alignRight: true, label: 'Remove Parent' }
];

function Aliases({ parent, aliases, removeAlias }) {
	const [getDatahubApi] = useApiLookup();
	const [isRemoving, setIsRemoving] = useState();
	const { showSnackbar } = useSnackbar();

	const remove = (event) => {
		const dataSet = event.currentTarget.dataset;
		const id = parseInt(dataSet.id, 0);
		const index = parseInt(dataSet.index, 0);
		setIsRemoving(id);

		removeParent(getDatahubApi, parent.extracted_entity_id, id)
			.then(() => {
				removeAlias(index);
			})
			.catch(() => {
				showSnackbar(`We're unable to remove the parent of that alias, please try again.`, snackbarVariants.error);
			})
			.finally(() => setIsRemoving(false));
	};

	return (
		<TableContainer>
			<Table id="aliases" size="small">
				<TableHead>
					<TableRow>
						{headCells.map((headCell) => (
							<TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
								{headCell.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow className="parent">
						<TableCell>{parent.name} (All)</TableCell>
						<TableCell align="right">{parent.items_count}</TableCell>
						<TableCell align="right"></TableCell>
					</TableRow>

					{aliases.map((alias, index) => (
						<TableRow key={alias.extracted_entity_id}>
							<TableCell>{alias.name}</TableCell>
							<TableCell align="right">{alias.items_count}</TableCell>
							<TableCell align="right">
								{isRemoving === alias.extracted_entity_id ? (
									<CircularProgress className="remove-loading" size={20} />
								) : (
									<IconButton className="remove" onClick={remove} data-index={index} data-id={alias.extracted_entity_id}>
										<Close />
									</IconButton>
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default Aliases;
