import Content from '@truescope-web/react/lib/components/layout/Content';
import Header from '../../../components/Header';
import WorkspacesTable from './WorkspacesTable';

const Workspaces = () => {
	return (
		<Content>
			<Header header="Workspaces" />
			<WorkspacesTable />
		</Content>
	);
};

export default Workspaces;
