import { useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';

const JsonViewer = ({ object }) => {
	const [isOpen, setIsOpen] = useState(false);
	const json = useMemo(() => JSON.stringify(object, null, '\t'), [object]);

	return (
		<div className="json-viewer">
			<div className="json-viewer__header">
				<ToolbarButton
					title="view json"
					size="xs"
					icon={isOpen ? <ExpandMore /> : <KeyboardArrowRight />}
					onClick={() => setIsOpen(!isOpen)}
				/>
			</div>
			<div className={`json-viewer__body ${isOpen ? 'json-viewer__body--open' : ''}`}>
				<Scrollbars>
					<TextField minRows={30} maxRows={100} multiline value={json} />
				</Scrollbars>
			</div>
		</div>
	);
};

export default JsonViewer;
