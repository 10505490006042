import { Outlet } from 'react-router';
import Drawer from './Drawer/Drawer';

const IntervalView = ({ _header, _breadcrumbs, ...props }) => {
	return (
		<>
			<Drawer {...props} />
			<div className="view">
				<Outlet />
			</div>
		</>
	);
};

export default IntervalView;
