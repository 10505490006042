import { useCallback } from 'react';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import CitiesSelect from '../../../components/Geography/CitiesSelect';
import CountriesSelect from '../../../components/Geography/CountriesSelect';
import RegionsSelect from '../../../components/Geography/RegionSelect';

const SourceGeography = ({ sourceGeography, onChange }) => {
	const handleCountriesChange = useCallback(
		(countries, isInitialization) => {
			onChange({ ...sourceGeography, countries: arrayIsNullOrEmpty(countries) ? [] : countries }, isInitialization);
		},
		[onChange, sourceGeography]
	);

	const handleRegionsChange = useCallback(
		(regions, isInitialization) => {
			onChange({ ...sourceGeography, regions: arrayIsNullOrEmpty(regions) ? [] : regions }, isInitialization);
		},
		[onChange, sourceGeography]
	);

	const handleCitiesChange = useCallback(
		(cities, isInitialization) => {
			onChange({ ...sourceGeography, cities: arrayIsNullOrEmpty(cities) ? [] : cities }, isInitialization);
		},
		[onChange, sourceGeography]
	);

	return (
		<Grid container>
			<Grid item>
				<CountriesSelect
					countries={sourceGeography?.countries}
					onChange={(_e, _v, rawValue, isInitialization) => handleCountriesChange(rawValue, isInitialization)}
				/>
			</Grid>
			<Grid item>
				<RegionsSelect
					regions={sourceGeography?.regions}
					countries={sourceGeography?.countries}
					onChange={(_e, _v, rawValue, isInitialization) => handleRegionsChange(rawValue, isInitialization)}
					disabled={arrayIsNullOrEmpty(sourceGeography?.countries)}
				/>
			</Grid>
			<Grid item>
				<CitiesSelect
					regions={sourceGeography?.regions}
					countries={sourceGeography?.countries}
					cities={sourceGeography?.cities}
					onChange={(_e, _v, rawValue, isInitialization) => handleCitiesChange(rawValue, isInitialization)}
					disabled={arrayIsNullOrEmpty(sourceGeography?.regions)}
				/>
			</Grid>
		</Grid>
	);
};

export default SourceGeography;
