import { createContext, useContext, useEffect } from 'react';
import { useState } from 'react';
import AppLoader from '@truescope-web/react/lib/components/loading/AppLoader';
import createLookup from '@truescope/utils/lib/arrays/createLookup';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import ExternalError from '../views/Error/ExternalError';
import { useApiLookup } from './ApiLookupProvider';
import { createFilterConfig, createMediaItemTableConfig } from './ConfigProviderConstants';

export const ConfigContext = createContext(null);

export const useConfig = () => {
	const context = useContext(ConfigContext);
	if (isNullOrUndefined(context)) {
		throw new Error('ConfigContext must be used within a FilterConfigProviderCacheProvider');
	}
	return context;
};

const getConfig = async (getDatahubApi) => {
	const api = await getDatahubApi();

	const { data: config } = await api.get('/config/v1');

	const localConfig = {
		clientUser: config.clientUser,
		scopeOptions: (config.scopes || []).map((scope) => ({ label: scope.name, value: scope.scope_id, metadata: scope })),
		queryOptions: (config.queries || []).map((query) => ({
			label: `${query.name} (${query.workspace_name})`,
			value: query.query_id,
			metadata: query
		})),
		roleOptions: (config.roles || []).map((role) => ({ label: role.name, value: role.role_id })),
		filterConfig: createFilterConfig(config),
		mediaItemTableConfig: createMediaItemTableConfig(config),
		contentRights: config.contentRights,
		mediaTypes: config.mediaTypes,
		mediaTypesLookup: createLookup(config.mediaTypes, 'media_type_id'),
		searchFieldVariants: config.searchFieldVariants,
		markets: config.markets,
		marketsLookup: createLookup(config.markets, 'market_id')
	};

	if (config.isAdmin) {
		localConfig.isAdmin = true;
	}

	return localConfig;
};

/**
 * contains the states for the app config
 * @param {*} param0
 */
export const ConfigProvider = ({ children }) => {
	const [getDatahubApi] = useApiLookup();
	const [config, setConfig] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		setErrorMessage(null);
		setConfig(null);
		getConfig(getDatahubApi)
			.then((newConfig) => {
				setConfig(newConfig);
			})
			.catch((e) => {
				const msg = `failed to get config - ${e.message}`;
				console.error(msg, e);
				setErrorMessage(msg);
			});
	}, [getDatahubApi, setErrorMessage, setConfig]);

	const renderChildren = () => {
		if (!stringIsNullOrEmpty(errorMessage)) {
			return <ExternalError errorMessage={errorMessage} />;
		}

		if (isNullOrUndefined(config)) {
			return <AppLoader />;
		}

		return children;
	};

	return <ConfigContext.Provider value={{ config, setConfig }}>{renderChildren()}</ConfigContext.Provider>;
};
