import { v4 as uuidv4 } from 'uuid';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';

/**
 * uploads a file to s3
 * @param {object} param0 getDatahubApi
 * @param {string} file name of the file
 * @param {string} data data for the file
 * @returns string file url
 */
export const uploadFile = async (getDatahubApi, file, uploadType, data) => {
	const api = await getDatahubApi();
	const response = await api.put(`/file-uploads/v1/${uploadType}/${file}`, { data });
	return response.data;
};

/**
 * deletes a file from s3
 * @param {object} param0 getDatahubApi
 * @param {string} file name of the file
 */
export const deleteFile = async (getDatahubApi, uploadType, file) => {
	const api = await getDatahubApi();
	api.delete(`/file-uploads/v1/${uploadType}/${file}`);
};

/**
 * obfuscates a file name with a guid
 * @param {string} fileName name of a file
 * @returns guid filename
 */
export const obfuscateFileName = (fileName) => {
	if (stringIsNullOrEmpty(fileName)) {
		return uuidv4();
	}
	const extensionIndex = fileName.lastIndexOf('.');
	if (extensionIndex < 0) {
		return uuidv4();
	}
	const extension = fileName.substring(extensionIndex);
	return `${uuidv4()}${extension}`;
};
