import { useCallback } from 'react';
import useCancelToken, { wasTokenCancelled } from '@truescope-web/react/lib/hooks/useCancelToken';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import stringFilterComparisonType from '@truescope/utils/lib/filters/stringFilterComparisonType';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import { useApiLookup } from '../../../../components/ApiLookupProvider';
import { useConfig } from '../../../../components/ConfigProvider';
import { isOption } from './ValueEditorConstants';

const createSourceRequestParameters = (input) => {
	const items = [];

	if (!stringIsNullOrEmpty(input)) {
		items.push({
			field: 'name',
			operator: 'contains',
			value: input,
			fromInput: ':r2b:'
		});
	}

	return {
		filterModel: {
			items
		},
		limit: 100,
		offset: 0,
		sortModel: [
			{
				field: 'name',
				sort: 'asc'
			}
		]
	};
};

const createRequestParameters = (input, initialValue, idField) => {
	if (!arrayIsNullOrEmpty(initialValue)) {
		return {
			whereClauses: [
				{
					field_name: idField,
					comparison_operator: 'IN',
					field_value: initialValue
				}
			],
			//the reason we don't use initialValue.length is because, for string matches, it could return MULTIPLE instances of the same object
			limit: 1000
		};
	}

	if (!stringIsNullOrEmpty(input)) {
		return {
			whereClauses: [
				{
					field_name: 'name',
					comparison_operator: 'ILIKE',
					field_value: `%${input}%`
				}
			],
			limit: 100
		};
	}

	return {};
};

const getSourceIdOptions = async (api, input, _initialValue, params, mediaTypesLookup) => {
	const { data } = await api.post(`/sources/v1`, createSourceRequestParameters(input), params);
	return (data.items || []).map((datum) => ({
		label: !isNullOrUndefined(datum.media_type_id) ? `${datum.name} (${mediaTypesLookup[datum.media_type_id].name})` : datum.name,
		value: datum.source_id
	}));
};

const getSourceNameOptions = async (api, input, initialValue, params, mediaTypesLookup) => {
	const { data } = await api.post(`/sources/v1`, createSourceRequestParameters(input), params);
	if (!arrayIsNullOrEmpty(initialValue) && arrayIsNullOrEmpty(data.items)) {
		//in this case, it's simple strings. we need to just return the initial value as options
		return initialValue.map((value) => (isOption(value) ? value : { value, label: value }));
	}
	return (data.items || []).map((datum) => ({
		label: !isNullOrUndefined(datum.media_type_id) ? `${datum.name} (${mediaTypesLookup[datum.media_type_id].name})` : datum.name,
		value: datum.name
	}));
};

const getRegionOptions = async (api, input, initialValue, params) => {
	const { data } = await api.post(
		'/geographies/v1/regions',
		!arrayIsNullOrEmpty(initialValue)
			? { regionIds: initialValue }
			: {
					filterValue: input,
					filterType: stringFilterComparisonType.startsWith
				},
		params
	);
	return (data || []).map((datum) => ({ label: datum.name, value: datum.country_id }));
};

const getCityOptions = async (api, input, initialValue, params) => {
	const { data } = await api.post(
		'/geographies/v1/cities',
		!arrayIsNullOrEmpty(initialValue)
			? { cityIds: initialValue }
			: {
					filterValue: input,
					filterType: stringFilterComparisonType.startsWith
				},
		params
	);
	return (data || []).map((datum) => ({ label: datum.name, value: datum.city_id }));
};

const getCountryOptions = async (api, input, initialValue, params) => {
	const { data } = await api.post(
		'/geographies/v1/countries',
		!arrayIsNullOrEmpty(initialValue)
			? { countryIds: initialValue }
			: {
					filterValue: input,
					filterType: stringFilterComparisonType.startsWith
				},
		params
	);
	return (data || []).map((datum) => ({ label: datum.name, value: datum.country_id }));
};

const getLocationOptions = async (api, input, initialValue, params) => {
	const { data } = await api.post(`/locations/v1`, createRequestParameters(input, initialValue, 'extracted_entity_id'), params);
	return (data.items || []).map((datum) => ({ label: datum.name, value: datum.extracted_entity_id }));
};

const getPeopleOptions = async (api, input, initialValue, params) => {
	const { data } = await api.post(`/people/v1`, createRequestParameters(input, initialValue, 'extracted_entity_id'), params);
	return (data.items || []).map((datum) => ({ label: datum.name, value: datum.extracted_entity_id }));
};

const getCompanyOptions = async (api, input, initialValue, params) => {
	const { data } = await api.post(`/companies/v1`, createRequestParameters(input, initialValue, 'extracted_entity_id'), params);
	return (data.items || []).map((datum) => ({ label: datum.name, value: datum.extracted_entity_id }));
};

const getAuthorOptions = async (api, input, initialValue, params) => {
	const { data } = await api.post(`/authors/v1/`, createRequestParameters(input, initialValue, 'author_id'), params);
	return (data.items || []).map((author) => ({ label: author.name, value: author.author_id }));
};

export const useOptionsSearch = (field) => {
	const [resetToken] = useCancelToken();
	const [getDatahubApi] = useApiLookup();
	const { config } = useConfig();

	const optionsSearch = useCallback(
		async (input, initialValue) => {
			const token = resetToken();
			try {
				const api = await getDatahubApi();
				const params = {
					cancelToken: token
				};
				switch (field) {
					case 'source_source_id':
						return await getSourceIdOptions(api, input, initialValue, params, config.mediaTypesLookup);
					case 'source_source_name':
						return await getSourceNameOptions(api, input, initialValue, params, config.mediaTypesLookup);
					case 'geography_region_ids':
						return await getRegionOptions(api, input, initialValue, params);
					case 'geography_city_ids':
						return await getCityOptions(api, input, initialValue, params);
					case 'geography_country_ids':
						return await getCountryOptions(api, input, initialValue, params);
					case 'locations_mentioned':
						return await getLocationOptions(api, input, initialValue, params);
					case 'people_mentioned':
						return await getPeopleOptions(api, input, initialValue, params);
					case 'companies_mentioned':
						return await getCompanyOptions(api, input, initialValue, params);
					case 'author_names':
						return await getAuthorOptions(api, input, initialValue, params);
					default:
						throw new Error(`unsupported option search for field '${field}'`);
				}
			} catch (e) {
				if (wasTokenCancelled(token)) {
					console.error(`failed to search for ${field} options - ${e.message}`);
				}
				return [];
			}
		},
		[field, resetToken]
	);

	return optionsSearch;
};
