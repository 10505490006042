import { useCallback } from 'react';
import useCancelToken, { wasTokenCancelled } from '@truescope-web/react/lib/hooks/useCancelToken';
import stringFilterComparisonType from '@truescope/utils/lib/filters/stringFilterComparisonType';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import { useApiLookup } from '../ApiLookupProvider';

const useGeographiesApi = () => {
	const [getDatahubApi] = useApiLookup();
	const [resetCountriesToken] = useCancelToken();
	const [resetRegionsToken] = useCancelToken();
	const [resetCitiesToken] = useCancelToken();

	const handleGetCountries = useCallback(
		async ({ filterValue, filterType, countryIds, limit = 10, sort = 'name', desc = false } = {}) => {
			const token = resetCountriesToken();
			try {
				const api = await getDatahubApi();
				const { data: countries } = await api.post(
					'/geographies/v1/countries',
					{
						filterValue,
						filterType:
							stringIsNullOrEmpty(filterType) && !stringIsNullOrEmpty(filterValue)
								? stringFilterComparisonType.startsWith
								: undefined,
						countryIds,
						limit,
						sort,
						desc
					},
					{
						cancelToken: token
					}
				);
				return countries.map((country) => ({ label: country.name, value: country.country_id }));
			} catch (e) {
				if (wasTokenCancelled(token)) {
					console.error(`failed to get countries - ${e.message}`);
				}
				return [];
			}
		},
		[getDatahubApi, resetCountriesToken]
	);

	const handleGetRegions = useCallback(
		async ({ filterValue, filterType, countryIds, regionIds, limit = 10 } = {}) => {
			const token = resetRegionsToken();
			try {
				const api = await getDatahubApi();
				const { data: regions } = await api.post(
					'/geographies/v1/regions',
					{
						filterValue,
						filterType:
							stringIsNullOrEmpty(filterType) && !stringIsNullOrEmpty(filterValue)
								? stringFilterComparisonType.startsWith
								: undefined,
						countryIds,
						regionIds,
						limit
					},
					{
						cancelToken: token
					}
				);
				return regions.map((region) => ({ label: region.display_name, value: region.region_id }));
			} catch (e) {
				if (wasTokenCancelled(token)) {
					console.error(`failed to get regions - ${e.message}`);
				}
				return [];
			}
		},
		[getDatahubApi, resetRegionsToken]
	);

	const handleGetCities = useCallback(
		async ({ filterValue, filterType, countryIds, regionIds, cityIds, limit = 10 } = {}) => {
			const token = resetCitiesToken();
			try {
				const api = await getDatahubApi();
				const { data: cities } = await api.post(
					'/geographies/v1/cities',
					{
						filterValue,
						filterType:
							stringIsNullOrEmpty(filterType) && !stringIsNullOrEmpty(filterValue)
								? stringFilterComparisonType.startsWith
								: undefined,
						countryIds,
						regionIds,
						cityIds,
						limit
					},
					{
						cancelToken: token
					}
				);
				return cities.map((city) => ({ label: city.display_name, value: city.city_id }));
			} catch (e) {
				if (wasTokenCancelled(token)) {
					console.error(`failed to get cities - ${e.message}`);
				}
				return [];
			}
		},
		[getDatahubApi, resetCitiesToken]
	);

	return {
		getCountries: handleGetCountries,
		getRegions: handleGetRegions,
		getCities: handleGetCities
	};
};

export default useGeographiesApi;
