import DataGrid, { Column, FilterRow, HeaderFilter, Pager, Paging, RemoteOperations } from 'devextreme-react/data-grid';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Sheet from '@truescope-web/react/lib/components/modal/SheetV2';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import MediaItem from '../../views/Content/MediaItem/MediaItem';
import { useApiLookup } from '../ApiLookupProvider';
import { useConfig } from '../ConfigProvider';
import { formatPagingInfoText } from '../Dx/DxGridConstants';
import { createSearchDataSource, renderDateCell, renderSourceCell, renderSummaryCell } from './MediaItemTableConstants';

const MediaItemTable = ({
	setIsLoading,
	dataTriggerGuid,
	filter,
	queryText,
	annotation,
	scope,
	defaultPageSize = 10,
	allowedPageSizes = [5, 10, 20],
	showBorders = true,
	includeLanguageFields = false,
	useWhereClauses = false,
	requestParams
}) => {
	const { config } = useConfig();
	const [getDatahubApi] = useApiLookup();
	const [dataSource, setDataSource] = useState(null);
	const [currentFilter] = useState('auto');
	const [languageFields, setLanguageFields] = useState([]);
	const [selected, setSelected] = useState(null);
	const [showMediaItemSheet, setShowMediaItemSheet] = useState(false);

	const dataGrid = useRef(null);

	useEffect(() => {
		if (!includeLanguageFields || !arrayIsNullOrEmpty(languageFields)) {
			return;
		}

		const defaultFields = ['summary', 'title', 'body'];
		const mediaItemFields = config.filterConfig.filterFieldOptions;
		const allFieldsMeta = mediaItemFields.filter((a) => a.value === 'all_fields_meta');
		const fields = allFieldsMeta[0]?.metadata?.metadata?.fields || [];

		setLanguageFields(!arrayIsNullOrEmpty(fields) ? fields : defaultFields);
	}, [languageFields]);

	useEffect(() => {
		if (isNullOrUndefined(dataTriggerGuid)) {
			setDataSource(null);
			return;
		}

		const ds = createSearchDataSource(
			getDatahubApi,
			() => setIsLoading(true),
			() => setIsLoading(false),
			filter,
			annotation,
			scope,
			queryText,
			languageFields,
			useWhereClauses,
			{ ...requestParams, query_to_timestamp: moment().utc().toISOString() }
		);
		setDataSource(ds);
	}, [languageFields, dataTriggerGuid]);

	const handleClickCell = ({ data }) => {
		if (isNullOrUndefined(data)) {
			return;
		}

		setSelected(data.id);
		setShowMediaItemSheet(true);
	};

	const renderHeaderCell = (data) => {
		return <div className="wrap">{data.column.caption}</div>;
	};

	if (isNullOrUndefined(dataSource)) {
		return null;
	}

	return (
		<>
			<div className="media-item-table">
				<DataGrid
					id="media-item-table"
					showRowLines={true}
					ref={dataGrid}
					dataSource={dataSource}
					onCellClick={handleClickCell}
					showColumnLines={false}
					showBorders={showBorders}
					onContentReady={formatPagingInfoText}
				>
					<FilterRow visible={true} applyFilter={currentFilter} />

					<HeaderFilter visible={true} />

					<Column
						caption="Source"
						width={250}
						dataField="primary_source_name"
						cssClass="cell cell--pad clickable"
						headerCellRender={renderHeaderCell}
						cellRender={renderSourceCell}
						allowFiltering={false}
						allowSorting={true}
						allowSearch={false}
					>
						<HeaderFilter allowSearch={true} />
					</Column>

					<Column
						caption="Summary"
						dataField="summary"
						cssClass="cell cell--pad clickable"
						allowFiltering={false}
						allowSorting={false}
						allowSearch={false}
						headerCellRender={renderHeaderCell}
						cellRender={renderSummaryCell}
					>
						<HeaderFilter allowSearch={true} />
					</Column>

					<Column
						caption="Publication Date"
						width={150}
						dataType="date"
						dataField="publication_date"
						alignment="right"
						cssClass="cell cell--pad clickable"
						headerCellRender={renderHeaderCell}
						allowHeaderFiltering={false}
						allowFiltering={false}
						allowSorting={true}
						allowSearch={false}
						cellRender={renderDateCell}
					>
						<HeaderFilter allowSearch={true} />
					</Column>

					<RemoteOperations sorting={true} paging={true} filtering={true} />

					<Paging defaultPageSize={defaultPageSize} />

					<Pager showPageSizeSelector={true} allowedPageSizes={allowedPageSizes} showInfo={true} />
				</DataGrid>
			</div>
			<Sheet isOpen={showMediaItemSheet} onClose={() => setShowMediaItemSheet(false)}>
				<div className="sheet-content">
					<MediaItem document_id={selected} onClose={() => setShowMediaItemSheet(false)} />
				</div>
			</Sheet>
		</>
	);
};

export default MediaItemTable;
