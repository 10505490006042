import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import moment from 'moment';
import { createContext, useContext } from 'react';
import { useReducer } from 'react';
import { useCallback } from 'react';
import AppLoader from '@truescope-web/react/lib/components/loading/AppLoader';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import SignIn from './SignIn';
import { getAuthState, getRefreshToken } from './constants';
import googleAuthReducer, {
	clearAuthenticationState,
	getInitialState,
	resetAuthenticationState,
	setAuthenticationState,
	setErrorMessage
} from './reducer';

const GoogleAuthContext = createContext();

/**
 * usage of google auth context
 */
export const useGoogleAuthContext = () => {
	const context = useContext(GoogleAuthContext);
	if (isNullOrUndefined(context)) {
		throw new Error('useGoogleAuthContext must be used within a GoogleAuthProvider');
	}
	return context;
};

/**
 * contains the states for the api lookup
 * @param {*} param0
 */
const GoogleAuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(googleAuthReducer, getInitialState(getAuthState()));

	const logout = useCallback(async () => {
		try {
			await googleLogout();
		} catch (e) {
			console.error(`failed to log out of google - ${e.message}`, e);
		}
		dispatch(clearAuthenticationState());
	}, [dispatch]);

	const login = useGoogleLogin({
		onSuccess: async ({ code }) => {
			try {
				dispatch(resetAuthenticationState());
				const { accessToken, expiry, refreshToken, user } = await getRefreshToken({ code });
				dispatch(setAuthenticationState(refreshToken, accessToken, moment(expiry).toISOString(false), user));
			} catch (e) {
				const msg = stringIsNullOrEmpty(e.response?.data?.message) ? `Failed to log in - ${e.message}` : e.response.data.message;
				console.error(msg);
				dispatch(setErrorMessage(msg));
				logout();
			}
		},
		onError: (e) => {
			console.error(`login failed - ${e.message}`);
			logout();
		},
		prompt: 'consent',
		flow: 'auth-code',
		scope: 'openid email'
	});

	const renderContent = () => {
		if (!state.isAuthenticated) {
			return <SignIn login={login} errorMessage={state.errorMessage} isLoading={state.isLoading} />;
		}

		if (state.isLoading) {
			return <AppLoader />;
		}

		return children;
	};

	return <GoogleAuthContext.Provider value={[state, dispatch, logout]}>{renderContent()}</GoogleAuthContext.Provider>;
};

export default GoogleAuthProvider;
