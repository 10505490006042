import { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import Add from '@mui/icons-material/Add';
import Link from '@mui/material/Link';
import Button from '@truescope-web/react/lib/components/form/Button';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Sheet from '@truescope-web/react/lib/components/modal/SheetV2';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import { useApiLookup } from '../../../../../components/ApiLookupProvider';
import { WorkspaceScopesTable } from '../../../../../components/Table/WorkspaceScopesTable';
import MoreOptionsMenu from '../../../../../components/Widgets/MoreOptionsMenu';
import AddScopesSheet from './AddScopesSheet';
import { removeScopeFromWorkspace, updateWorkspaceScopeSortOrder } from './api';
import { reorderScopes } from './constants';

const Scopes = ({ scopes, setScopes, workspace }) => {
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();
	const [isSheetOpen, setIsSheetOpen] = useState(false);
	const navigate = useNavigate();

	const handleRemoveScopeClick = (scope) => {
		const scopeId = scope.workspace_scope_id;
		const workspaceId = workspace.workspace_id;
		removeScopeFromWorkspace(getDatahubApi, workspaceId, scopeId).then(({ message }) => {
			if (!stringIsNullOrEmpty(message)) {
				showSnackbar(message, snackbarVariants.error);
				return;
			}

			setScopes((prev) => prev.filter((scope) => scope.workspace_scope_id !== scopeId));
		});
	};

	const handleAddScopes = useCallback(
		(newScopes) => {
			setScopes((prev) => prev.concat(newScopes));
		},
		[scopes]
	);

	const handleAddScopeClick = useCallback((_e) => setIsSheetOpen(true));

	const handleEditScopeClick = (scope) => {
		view(scope);
	};

	const view = ({ scope_id }) => {
		navigate(`/media/feeds/${scope_id}`);
	};

	const renderScopeNameColumn = ({ scope_id, name }) => {
		return (
			<div className="wrap">
				<Typography variant="row-link">
					<Link onClick={() => navigate(`/media/feeds/${scope_id}`)}>{name}</Link>
				</Typography>
			</div>
		);
	};

	const renderItemColumn = (scope_id, value) => {
		return (
			<div className="wrap">
				<Typography variant="row-link">
					<Link onClick={() => navigate(`/clients/workspaces/${scope_id}`)}>{value}</Link>
				</Typography>
			</div>
		);
	};

	const renderOptionsColumn = (scope) => {
		return (
			<div className="wrap">
				<MoreOptionsMenu
					size="md"
					options={[
						{
							label: 'Edit',
							onClick: () => handleEditScopeClick(scope)
						},
						{
							label: 'Remove',
							onClick: () => handleRemoveScopeClick(scope)
						}
					]}
				/>
			</div>
		);
	};

	const onTableItemChange = useCallback(
		(scope, draggedFromIndex, draggedToIndex) => {
			let previousScopes = [];
			setScopes((prev) => {
				previousScopes = prev;

				return reorderScopes(scopes, draggedFromIndex, draggedToIndex);
			});

			updateWorkspaceScopeSortOrder(
				getDatahubApi,
				workspace.workspace_id,
				scope.workspace_scope_id,
				draggedToIndex + 1,
				draggedFromIndex + 1
			).then(({ message }) => {
				if (!stringIsNullOrEmpty(message)) {
					showSnackbar(message, snackbarVariants.error);
					setScopes(previousScopes);
				}
			});
		},
		[workspace.workspace_id, getDatahubApi, scopes, setScopes]
	);

	const tableData = useMemo(
		() =>
			scopes.map((scope) => ({
				...scope,
				id: scope.scope_id,
				nameLink: renderScopeNameColumn(scope),
				itemCountMonthLink: renderItemColumn(scope.scope_id, scope.item_count_month),
				itemCountForecastLink: renderItemColumn(scope.scope_id, scope.item_count_forecast),
				menu: renderOptionsColumn(scope, false)
			})),
		[scopes]
	);
	const tableColumns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'nameLink'
			},
			{
				Header: () => (
					<div className="workspace-scopes__table__options__header">
						<Button fab={<Add />} tooltip="Add Feed" variant="primary" onClick={handleAddScopeClick} />
					</div>
				),
				Cell: (row) => {
					return <div className="workspace-scopes__table__options__header__row">{row.value}</div>;
				},
				accessor: 'menu',
				disableSortBy: true
			}
		],
		[handleAddScopeClick]
	);

	return (
		<div className="workspace-scopes">
			<Grid item className="workspace-scopes__title">
				<Typography variant="subtitle">Add Feeds to this Workspace</Typography>
			</Grid>
			<WorkspaceScopesTable columns={tableColumns} data={tableData} onChange={onTableItemChange} />
			<Sheet isOpen={isSheetOpen} onClose={() => setIsSheetOpen(false)}>
				<AddScopesSheet
					onClose={() => setIsSheetOpen(false)}
					workspace_id={workspace.workspace_id}
					existingScopes={scopes}
					onAddScopes={handleAddScopes}
				/>
			</Sheet>
		</div>
	);
};

export default memo(Scopes);
