import Select from 'react-select';
import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@truescope-web/react/lib/components/form/Button';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import Header from '../../../components/Header';
import '../../../scss/views/Entities/EntitiesList/EntitiesList.scss';
import Table from './Table';
import EntitiesProvider, { useEntities } from './provider';

const Entities = () => {
	const {
		handleSearch,
		setSearchValue,
		isLoading,
		search,
		isLoadingMore,
		setIsLoadingMore,
		errorMessage,
		entities,
		totalCount,
		offset,
		limit,
		entityTypes,
		selectedEntityType,
		setSelectedEntityType
	} = useEntities();

	const handleEnterKeypress = (e) => (e.keyCode === 13 && !isLoading ? handleOnSearch() : null);

	const onEntityChange = (option) => setSelectedEntityType(isNullOrUndefined(option) ? entityTypes[0].value : option);

	const handleOnSearch = async () => {
		if (isLoadingMore) {
			return;
		}
		await handleSearch(0);
	};

	const handleLoadMore = () => {
		if (isLoadingMore) {
			return;
		}
		setIsLoadingMore(true);
		search(offset + limit).then(() => setIsLoadingMore(false));
	};

	return (
		<div id="entities-list">
			<Content>
				<Header header="Entities" />
				<Grid container>
					<Grid item>
						<Typography variant="subtitle">Search for a company, person or place</Typography>
					</Grid>
					<Grid item>
						<Inline>
							<div className="textfield-container">
								<TextField
									inputProps={{ className: 'input-element' }}
									variant="outlined"
									size="small"
									className={'textfield-container__mui-control'}
									onChange={(e) => setSearchValue(e.target.value)}
									onKeyDown={handleEnterKeypress}
								/>
							</div>
							<Select
								className="select"
								options={entityTypes}
								value={selectedEntityType}
								onChange={onEntityChange}
								menuPosition="fixed"
								isDisabled={isLoading || isLoadingMore}
							/>
							<Button
								onClick={handleOnSearch}
								variant="primary"
								loading={isLoading || isLoadingMore}
								disabled={isNullOrUndefined(selectedEntityType)}
							>
								Search
							</Button>
						</Inline>
					</Grid>
					<Grid item>{errorMessage ? errorMessage : <Table />}</Grid>
					<Grid item>
						{(!isLoading && !arrayIsNullOrEmpty(entities) ? entities.length : null) !== totalCount && (
							<div id="entities-pagination">
								{isLoadingMore ? (
									<CircularProgress />
								) : (
									<Button variant="outlined" color="primary" onClick={handleLoadMore}>
										Load more
									</Button>
								)}
							</div>
						)}
					</Grid>
				</Grid>
			</Content>
		</div>
	);
};

const WrappedEntities = () => (
	<EntitiesProvider>
		<Entities />
	</EntitiesProvider>
);

export default WrappedEntities;
