import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import formatDate from '@truescope/utils/lib/dates/formatDate';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import { workspaceUserStatesLookup } from '@/constants';

/**
 * converts the users
 * @param {*} users
 */
const convertUsers = (users, roleOptions) => {
	return users.map((user) => {
		if (user.workspace_user_state_id === workspaceUserStatesLookup.active) {
			user.statusLabel = 'Active';
			user.statusVariant = 'info';
		} else {
			user.statusLabel = 'Inactive';
			user.statusVariant = 'disabled';
		}

		if (!arrayIsNullOrEmpty(roleOptions) && !isNullOrUndefined(user.role_id)) {
			const role = roleOptions.find((role) => role.value === user.role_id);
			user.role = role.label.replace('Workspace ', '');
		} else {
			user.role = 'Viewer';
		}

		return {
			...user,
			...formatDate('last_login', user)
		};
	});
};

/**
 * creates a local data source for users
 * @param {*} users
 */
export const createUsersDataSource = (users, roleOptions) => {
	const converted = convertUsers(users, roleOptions);
	return {
		store: new CustomStore({
			load: () => {
				return Promise.resolve({
					data: converted,
					totalCount: converted.length,
					key: 'user_id'
				});
			}
		}),
		cache: {
			users: converted,
			totalCount: converted.length
		}
	};
};

/**
 * converts the invites
 * @param {*} invites
 */
const convertInvites = (invites) => {
	return invites.map((invite) => {
		if (moment.utc(invite.expiration, 'YYYY-MM-DD HH:mm:ss').isAfter(moment.utc())) {
			// If the expiration is after today, the invite is active
			invite.statusVariant = 'success';
			invite.statusLabel = 'Invite Sent';
		} else {
			// If the expiration is before today, the invite is expired
			invite.statusVariant = 'warning';
			invite.statusLabel = 'Expired';
		}
		return {
			...invite,
			...formatDate('expiration', invite)
		};
	});
};

/**
 * creates a local data source for invites
 * @param {*} invites
 */
export const createInvitesDataSource = (invites) => {
	return {
		store: new CustomStore({
			load: () => {
				return Promise.resolve({
					data: convertInvites(invites),
					totalCount: invites.length
				});
			}
		})
	};
};
