import { useEffect, useMemo, useState } from 'react';
import Select from '@truescope-web/react/lib/components/form/Select';
import Switch from '@truescope-web/react/lib/components/form/Switch';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Alert from '@truescope-web/react/lib/components/modal/Alert';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import { useApiLookup } from '../../../../../components/ApiLookupProvider';
import AiMediaAssistantModal from './AiMediaAssistantModal';
import AiSummarizationModal from './AiSummarizationModal';
import { getCurrencies } from './constants';

const Options = ({ workspace, onSaveWorkspace, disabled }) => {
	const [isAiSummarizationModalOpen, setIsAiSummarizationModalOpen] = useState(false);
	const [isAiMediaAssistantModalOpen, setIsAiMediaAssistantModalOpen] = useState(false);
	const [showNlpPrompt, setShowNlpPrompt] = useState(false);
	const [getDatahubApi] = useApiLookup();
	const [currencyOptions, setCurrencyOptions] = useState([]);
	useEffect(() => {
		getCurrencies(getDatahubApi).then((newCurrencyOptions) => setCurrencyOptions(newCurrencyOptions));
	}, [getDatahubApi]);

	const currentCurrency = useMemo(
		() => (isNullOrUndefined(workspace.currency_code) ? undefined : { label: workspace.currency_code, value: workspace.currency_code }),
		[workspace?.currency_code]
	);

	const handleAiModalAccept = () => {
		setIsAiSummarizationModalOpen(false);
		setIsAiMediaAssistantModalOpen(false);
		onSaveWorkspace({
			...workspace,
			enable_ai_summarization: false,
			enable_media_assistant: false
		});
	};

	const handleAcceptEnableNlp = () => {
		setShowNlpPrompt(false);
		onSaveWorkspace({ ...workspace, enable_nlp: true });
	};

	const handleCancelEnableNlp = () => {
		setShowNlpPrompt(false);
	};

	const handleEnableNlpToggle = () => {
		if (workspace.enable_nlp) {
			// If we're disabling NLP, don't show the Alert prompt.
			onSaveWorkspace({ ...workspace, enable_nlp: false });
		} else {
			setShowNlpPrompt(true);
		}
	};

	const handleAveToggle = () => {
		const updatedWorkspace = {
			...workspace,
			show_ave: !workspace.show_ave
		};
		if (!updatedWorkspace.show_ave) {
			updatedWorkspace.currency_value = null;
		}
		onSaveWorkspace(updatedWorkspace);
	};

	const handleEnableCommentIngestionChange = () => {
		onSaveWorkspace({
			...workspace,
			enable_comments_collection: !workspace.enable_comments_collection
		});
	};

	const handleEnableMediaAssistantChange = () => {
		if (workspace.enable_ai_summarization && workspace.enable_media_assistant) {
			setIsAiMediaAssistantModalOpen(true);
		} else {
			onSaveWorkspace({
				...workspace,
				enable_media_assistant: !workspace.enable_media_assistant,
				enable_ai_summarization: !workspace.enable_media_assistant
			});
		}
	};

	const handleEnableAiSummarizationChange = () => {
		if (workspace.enable_ai_summarization) {
			setIsAiSummarizationModalOpen(true);
		} else {
			onSaveWorkspace({
				...workspace,
				enable_ai_summarization: !workspace.enable_ai_summarization
			});
		}
	};

	const handleCurrencyCodeChange = (_e, currencyCode) => {
		onSaveWorkspace({ ...workspace, currency_code: currencyCode });
	};

	return (
		<>
			<Grid container>
				<Grid item>
					<Typography variant="h4">Workspace Options</Typography>
				</Grid>
				<Grid item sm={6}>
					<Switch
						value={workspace.enable_nlp}
						onChange={handleEnableNlpToggle}
						label="Run NLP on matched items"
						color="primary"
						disabled={disabled}
					/>
				</Grid>
				<Grid item sm={6}>
					<Inline>
						<Switch
							value={workspace.show_ave}
							onChange={handleAveToggle}
							label="Show AVE"
							color="primary"
							disabled={disabled}
						/>
						{workspace.show_ave && (
							<Select
								options={currencyOptions}
								value={currentCurrency}
								defaultOptions={currencyOptions}
								onChange={handleCurrencyCodeChange}
								menuPosition="fixed"
								disabled={disabled}
							/>
						)}
					</Inline>
				</Grid>
				<Grid item sm={6}>
					<Switch
						value={workspace.enable_media_assistant}
						onChange={handleEnableMediaAssistantChange}
						label="Enable Media Assistant"
						color="primary"
						disabled={disabled}
					/>
				</Grid>
				<Grid item sm={6}>
					<Switch
						value={workspace.enable_ai_summarization}
						onChange={handleEnableAiSummarizationChange}
						label="Enable AI Summarization"
						color="primary"
						disabled={workspace.enable_media_assistant || disabled}
					/>
				</Grid>
				<Grid item sm={6}>
					<Switch
						value={workspace.enable_comments_collection}
						onChange={handleEnableCommentIngestionChange}
						label="Enable Comment Ingestion"
						color="primary"
						disabled={disabled}
					/>
				</Grid>
			</Grid>
			<Alert
				title="Enable NLP"
				message="Are you sure you want to enable NLP for this workspace? This aint cheap friendo"
				cancelLabel="No"
				acceptLabel="Yes"
				open={showNlpPrompt}
				handleCancel={handleCancelEnableNlp}
				handleAccept={handleAcceptEnableNlp}
			/>
			<AiSummarizationModal
				onAccept={handleAiModalAccept}
				onCancel={() => setIsAiSummarizationModalOpen(false)}
				isOpen={isAiSummarizationModalOpen}
			/>
			<AiMediaAssistantModal
				onAccept={handleAiModalAccept}
				onCancel={() => setIsAiMediaAssistantModalOpen(false)}
				isOpen={isAiMediaAssistantModalOpen}
			/>
		</>
	);
};

export default Options;
