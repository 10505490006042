import { useNavigate } from 'react-router';
import Tooltip from '@mui/material/Tooltip';
import Button from '@truescope-web/react/lib/components/form/Button';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import { extractError } from '../../../../components/Api';
import { useApiLookup } from '../../../../components/ApiLookupProvider';
import { createFeedRule, updateFeedRule } from './api';

const ToolbarButtons = ({ estimatedMonthlyTotal, setIsSaving, isSaving, feedRule, canSave, onPreview, canPreview }) => {
	const navigate = useNavigate();
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();

	const handleSaveRule = async () => {
		const saveRuleFunc = isNullOrUndefined(feedRule?.feed_rule_id) ? createFeedRule : updateFeedRule;
		setIsSaving(true);
		try {
			const { message } = await saveRuleFunc(getDatahubApi, feedRule);
			if (!stringIsNullOrEmpty(message)) {
				throw new Error(`Failed to create feed rule - ${message}`);
			}
			showSnackbar('Rule has been successfully saved', snackbarVariants.success);
			history.go(-1);
		} catch (e) {
			const msg = extractError(e);
			showSnackbar(
				msg.includes('feed_rules_feed_id_rule_key') ? 'This query conditions in this rule already exist in another rule' : msg,
				snackbarVariants.error
			);
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
			<div>
				{!isNullOrUndefined(estimatedMonthlyTotal) && (
					<>
						<p>Forecasted items per month</p>
						<h3>{estimatedMonthlyTotal}</h3>
					</>
				)}
			</div>
			<Button
				variant="secondary"
				onClick={() => {
					history.go(-1);
				}}
			>
				Cancel
			</Button>
			<Button disabled={!canPreview} variant="secondary" onClick={onPreview}>
				Preview
			</Button>
			{canSave ? (
				<Button disabled={isSaving} onClick={handleSaveRule}>
					Save
				</Button>
			) : (
				<Tooltip title="You must preview the query before saving, to ensure it works correctly">
					<span>
						<Button disabled>Save</Button>
					</span>
				</Tooltip>
			)}
		</Inline>
	);
};

export default ToolbarButtons;
