import { useCallback, useMemo } from 'react';
import AsyncSelect from '@truescope-web/react/lib/components/form/AsyncSelect';
import ChipTextField from '@truescope-web/react/lib/components/form/ChipTextField';
import Select from '@truescope-web/react/lib/components/form/Select';
import Switch from '@truescope-web/react/lib/components/form/Switch';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import TextFieldWithDebounce from '@truescope-web/react/lib/components/form/TextFieldWithDebounce';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import sentimentOptions from '@truescope/utils/lib/mediaItems/sentimentOptions';
import { useConfig } from '../../../../components/ConfigProvider';
import { getInputType, getRangeType, isOption } from './ValueEditorConstants';
import { useOptionsSearch } from './useOptionsSearch';

const TermsSelect = ({ field, value, ...props }) => {
	const { config } = useConfig();

	const options = useMemo(() => {
		switch (field) {
			case 'language_code':
				return config.filterConfig.filterValueOptions.languages;
			case 'category_names':
				return config.filterConfig.filterValueOptions.category_display_names;
			case 'source_media_type':
				return config.filterConfig.filterValueOptions.media_types;
			case 'sentiment':
				return sentimentOptions;
			default:
				throw new Error(`unsupported select field '${field}'`);
		}
	}, [
		config.filterConfig.filterValueOptions.category_display_names,
		config.filterConfig.filterValueOptions.languages,
		config.filterConfig.filterValueOptions.media_types,
		field
	]);

	const values = props.isMulti ? value.map((value) => {
		if (typeof value === 'string' || typeof value === 'number') {
			return { value, label: value };
		}

		return value;
	}) : value;

	return <Select {...props} value={values} options={options} className={`terms-select terms-select--${field}`} />;
};

const TermsAsyncSelect = ({ field, ...props }) => {
	const search = useOptionsSearch(field);
	const handleLoadOptions = useCallback(
		async (input, initialValue) => {
			if (isOption(initialValue)) {
				return initialValue;
			}
			try {
				const options = await search(input, initialValue);
				return options;
			} catch (e) {
				if (e.message !== 'cancelled') {
					console.error(`failed to search for ${field} options - ${e.message}`);
				}
			}
		},
		[field, search]
	);

	return <AsyncSelect {...props} onLoadOptions={handleLoadOptions} className={`terms-async-select terms-async-select--${field}`} />;
};

const BooleanEditor = ({ value, onChange }) => {
	return <Switch value={value} onChange={(e) => onChange(e.target.checked)} label={value ? 'Yes' : 'No'} />;
};

const QueryStringEditor = ({ value, onChange }) => {
	return <TextFieldWithDebounce minRows={1} maxRows={10} multiline value={value || ''} onChange={(e) => onChange(e.target.value)} />;
};

const TermsEditor = ({ value, onChange, field, filterFunction }) => {
	const isMulti = ['terms_set', 'terms'].includes(filterFunction);

	switch (field) {
		case 'language_code':
		case 'category_names':
		case 'source_media_type':
		case 'sentiment':
			return <TermsSelect value={value} field={field} onChange={(_e, _value, rawValue) => onChange(rawValue)} isMulti={isMulti} />;
		case 'source_source_name':
		case 'source_source_id':
		case 'geography_region_ids':
		case 'geography_city_ids':
		case 'geography_country_ids':
		case 'locations_mentioned':
		case 'people_mentioned':
		case 'companies_mentioned':
		case 'author_names':
			return (
				<TermsAsyncSelect value={value} field={field} onChange={(_e, _value, rawValue) => onChange(rawValue)} isMulti={isMulti} />
			);
		default:
			return isMulti ? (
				<ChipTextField minRows={1} maxRows={10} onChange={(_e, value) => onChange(value)} value={value} />
			) : (
				<TextField minRows={1} maxRows={10} multiline value={value || ''} onChange={(e) => onChange(e.target.value)} />
			);
	}
};

const MultiRangeInput = ({ value, onChange, field }) => {
	const inputType = useMemo(() => getInputType(field), [field]);

	const handleRangeChange = (newValue) => {
		onChange(newValue);
	};

	return (
		<div className="multi-range-editor">
			<TextField
				type={inputType}
				value={value?.from || ''}
				onChange={(e) => handleRangeChange({ ...(value || {}), from: e.target.value })}
			/>
			<div className="multi-range-editor__label-between">to</div>
			<TextField
				type={inputType}
				value={value?.to || ''}
				onChange={(e) => handleRangeChange({ ...(value || {}), to: e.target.value })}
			/>
		</div>
	);
};

const SingleRangeInput = ({ value, onChange, field, filterFunction }) => {
	const inputType = useMemo(() => getInputType(field), [field]);
	const rangeType = useMemo(() => getRangeType(filterFunction), [filterFunction]);

	const handleRangeChange = (newValue) => {
		onChange(newValue);
	};

	return (
		<TextField
			type={inputType}
			value={value?.[rangeType] || ''}
			onChange={(e) => handleRangeChange({ ...(value || {}), [rangeType]: e.target.value })}
		/>
	);
};

const RangeEditor = (props) => {
	switch (props.filterFunction) {
		case 'range':
		case 'range_gte_lte':
			return <MultiRangeInput {...props} />;
		case 'range_gt':
		case 'range_gte':
		case 'range_lt':
		case 'range_lte':
			return <SingleRangeInput {...props} />;
		default:
			throw new Error(`unsupported range filter function '${props.filterFunction}'`);
	}
};

const getComponentForField = (field) => {
	switch (field) {
		case 'body':
		case 'summary':
		case 'title':
		case 'all_fields':
		case 'all_fields_meta':
		case 'content_author':
		case 'item_url':
		case 'source_source_url':
		case 'item_id':
		case 'entity_provider':
			return QueryStringEditor;

		case 'author_names':
		case 'companies_mentioned':
		case 'people_mentioned':
		case 'locations_mentioned':
		case 'emoji':
		case 'hashtags':
		case 'language_code':
		case 'sentiment':
		case 'scope_names':
		case 'source_media_type':
		case 'category_names':
		case 'source_section_id':
		case 'source_section_name':
		case 'source_source_id':
		case 'source_source_name':
		case 'geography_country_ids':
		case 'geography_city_ids':
		case 'geography_region_ids':
		case 'accounts_mentioned':
			return TermsEditor;

		case 'influence':
		case 'audience':
		case 'duration':
		case 'sentiment_magnitude':
		case 'sentiment_score':
		case 'word_count':
		case 'source_source_online_rank':
		case 'found_date':
		case 'item_date':
		case 'modified_date':
		case 'publication_date':
			return RangeEditor;

		case 'source_source_paywalled':
			return BooleanEditor;

		default:
			throw new Error(`unknown field '${field}'`);
	}
};

const ValueEditor = ({ value, onChange, filterFunction, field }) => {
	const Component = useMemo(() => getComponentForField(field), [field]);

	return (
		<div className="value-editor">
			{isNullOrUndefined(Component) ? (
				<Typography variant="error">unsupported filter function &apos;{filterFunction}&apos;</Typography>
			) : (
				<Component value={value} onChange={onChange} field={field} filterFunction={filterFunction} />
			)}
		</div>
	);
};

export default ValueEditor;
