import { useCallback, useEffect, useMemo, useState } from 'react';
import AsyncSelect from '@truescope-web/react/lib/components/form/AsyncSelect';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import useGeographiesApi from './useGeographiesApi';

const RegionsSelect = ({ countries, regions, onChange, ...selectProps }) => {
	const geographiesApi = useGeographiesApi();
	const countryIds = useMemo(() => countries?.map((country) => country.value), [countries]);
	const [defaultRegions, setDefaultRegions] = useState([]);

	useEffect(() => {
		if (arrayIsNullOrEmpty(countryIds)) {
			setDefaultRegions(undefined);
			return;
		}
		geographiesApi?.getRegions({ countryIds }).then((newRegions) => setDefaultRegions(newRegions));
	}, [setDefaultRegions, geographiesApi?.getRegions, countryIds]);

	const handleLoadRegions = useCallback(
		(input) => geographiesApi?.getRegions({ filterValue: input, countryIds }),
		[geographiesApi?.getRegions, countryIds]
	);

	return (
		<AsyncSelect
			labelAbove
			isMulti
			label="State/Region"
			onLoadOptions={handleLoadRegions}
			defaultOptions={defaultRegions}
			value={regions}
			placeholder="Regions"
			onChange={(e, value, rawValue) => onChange(e, value, rawValue, false)}
			menuPosition="fixed"
			{...selectProps}
		/>
	);
};

export default RegionsSelect;
