import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Row from './Row';

const ClientUserWorkspacesTable = ({ user, setUser }) => {
	const handleChange = (updatedWorkspace) => {
		setUser((prev) => {
			const workspaceIndex = prev.workspaces.findIndex(({ workspace_id }) => workspace_id === updatedWorkspace.workspace_id);
			const workspaces = [...prev.workspaces];
			workspaces[workspaceIndex] = {
				...prev.workspaces[workspaceIndex],
				...updatedWorkspace
			};
			return {
				...prev,
				workspaces
			};
		});
	};

	return (
		<TableContainer className="client-user-workspaces-table">
			<Table size="small" aria-label="a dense table">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell width={100}>Role</TableCell>
						<TableCell width={60}>Active</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{user.workspaces.map((workspaceUser) => (
						<Row key={workspaceUser.workspace_id} workspaceUser={workspaceUser} onChange={handleChange} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default ClientUserWorkspacesTable;
