import set from 'lodash.set';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ChevronLeftIcon from '@mui/icons-material/ArrowLeftOutlined';
import ChevronRightIcon from '@mui/icons-material/ArrowRightOutlined';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Collapse, ListItemButton, Tooltip } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import { breakpointDown, breakpointUp } from '@truescope-web/react/lib/utils/breakpoints';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import badge from '../../images/logo-badge.png';
import logo from '../../images/logo-dark.png';
import { useConfig } from '../ConfigProvider';
import { useGoogleAuthContext } from '../GoogleAuth/GoogleAuthProvider';
import { getAuthState } from '../GoogleAuth/constants';
import { createDrawerFooterItems, createDrawerItems, findSelectedAndExpandedDrawerItems } from './DrawerConstants';

const DrawerWrapper = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { config } = useConfig();
	const { userStorage, setUserStorage } = useUserStorageContext();
	const [_googleAuthstate, _dispatchGoogleAuth, logout] = useGoogleAuthContext();
	const { user } = getAuthState();
	const [hover, setHover] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [expandedItem, setExpandedItem] = useState(null);
	const [clickedOpen, setClickedOpen] = useState(() => {
		const drawerOpen = userStorage.userSettings?.drawerOpen;

		return drawerOpen === undefined ? breakpointUp('md') : drawerOpen;
	});

	const drawerItems = createDrawerItems(config);

	const drawerFooterItems = createDrawerFooterItems(user, logout);

	const handleToggleClickedOpen = (newClickedOpenValue, shouldSetClickedOpen = false) => {
		if (shouldSetClickedOpen) {
			setClickedOpen(newClickedOpenValue);
		}

		setUserStorage((prev) => set({ ...prev }, 'userSettings.drawerOpen', newClickedOpenValue));

		if (!newClickedOpenValue) {
			setHover(false);
		}

		setExpandedItem(null);
	};

	useEffect(() => {
		const { selected, expanded } = findSelectedAndExpandedDrawerItems(drawerItems.concat(drawerFooterItems), location); //todo-dan check findSelectedAndExpandedDrawerItems
		if (selected && expanded) {
			setSelectedItem(selected.label);
			//dont expand when the menu is collapsed
			if (clickedOpen) {
				setExpandedItem(expanded.label);
			}
		}
	}, [location]);

	const handleClickDrawerItem = (drawerItem, hasChildren) => {
		if (hasChildren) {
			setExpandedItem(expandedItem === drawerItem.label ? null : drawerItem.label);
		}

		if (!isNullOrUndefined(drawerItem.onClick)) {
			drawerItem.onClick();
		}

		if (!stringIsNullOrEmpty(drawerItem.path)) {
			navigate(drawerItem.path);

			//on small views, close after we navigate
			if (breakpointDown('sm')) {
				handleToggleClickedOpen(false);
			}
		}
	};

	const handleHoverOn = (_e) => {
		setHover(true);
	};

	const handleHoverOff = (_e) => {
		setHover(false);
		if (!clickedOpen) {
			setExpandedItem(null);
		}
	};

	const handleIgnoreEvent = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const renderDrawerItem = (drawerItem, index, last, nested) => {
		const hasChildren = !arrayIsNullOrEmpty(drawerItem.items);
		return (
			<Fragment key={drawerItem.label}>
				<List disablePadding>
					<ListItemButton
						selected={drawerItem.label === selectedItem}
						aria-label={drawerItem.ariaLabel}
						disableGutters={nested}
						className={`drawer__item ${nested ? 'drawer__item--nested' : ''}`}
						classes={{ selected: 'drawer__item--selected' }}
						onClick={() => handleClickDrawerItem(drawerItem, hasChildren)}
						disabled={drawerItem.disabled}
					>
						{clickedOpen ? (
							<div className="drawer__item__icon">{drawerItem.icon}</div>
						) : (
							<Tooltip title={drawerItem.label} placement="right">
								<div className="drawer__item__icon">{drawerItem.icon}</div>
							</Tooltip>
						)}

						<ListItemText primary={<span className="drawer__item__label">{drawerItem.label}</span>} />
						{hasChildren ? expandedItem === drawerItem.label ? <ExpandMore /> : <KeyboardArrowRight /> : null}
					</ListItemButton>
					{hasChildren && (
						<Collapse in={expandedItem === drawerItem.label} timeout="auto" unmountOnExit>
							{drawerItem.items.map((childItem, index) =>
								renderDrawerItem(childItem, index, index + 1 < drawerItem.items.length, true)
							)}
						</Collapse>
					)}
				</List>
			</Fragment>
		);
	};

	const renderToolbar = () => {
		return (
			<div className="toolbar">
				{clickedOpen ? (
					<>
						<img className="logo" src={logo} alt="Truescope Datahub" />
						<Tooltip title="Collapse menu">
							<IconButton onClick={() => handleToggleClickedOpen(false, true)}>
								<ChevronLeftIcon />
							</IconButton>
						</Tooltip>
					</>
				) : (
					<>
						<img src={badge} className="logo-badge" alt="Truescope Datahub" width="32" />
						<Tooltip title="Pin menu">
							<IconButton onClick={() => handleToggleClickedOpen(true, true)}>
								<ChevronRightIcon className="pin-drawer" />
							</IconButton>
						</Tooltip>
					</>
				)}
			</div>
		);
	};

	const renderFooterItems = () => {
		return (
			<div className="drawer__footer">
				{drawerFooterItems.map((drawerItem, index) => renderDrawerItem(drawerItem, index, index + 1 < drawerItems.length, false))}
			</div>
		);
	};

	const renderDrawerWrapper = () => {
		const open = clickedOpen || hover;
		return (
			<Drawer
				variant="permanent"
				className={`drawer ${!open ? 'drawer--collapsed' : ''}`}
				classes={{
					paper: `paper ${!open ? 'paper--collapsed' : ''}`
				}}
				onMouseEnter={handleHoverOn}
				onMouseLeave={handleHoverOff}
				onTouchStart={handleHoverOn}
				onTouchEnd={handleHoverOff}
				open={open}
			>
				{renderToolbar()}
				{drawerItems.map((drawerItem, index) => renderDrawerItem(drawerItem, index, index + 1 < drawerItems.length, false))}

				{!arrayIsNullOrEmpty(drawerFooterItems) && renderFooterItems()}

				<div
					className="overlay"
					onClick={() => handleToggleClickedOpen(false)}
					onMouseEnter={handleHoverOff}
					onMouseLeave={handleIgnoreEvent}
					onTouchStart={handleHoverOff}
					onTouchEnd={handleIgnoreEvent}
				>
					&nbsp;
				</div>
			</Drawer>
		);
	};

	return renderDrawerWrapper();
};

export default DrawerWrapper;
