import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Alert from '@truescope-web/react/lib/components/modal/Alert';

const AiSummarizationModal = ({ onAccept, onCancel, isOpen }) => {
	return (
		<Alert
			title="Warning"
			renderContent={() => (
				<div>
					<Typography variant="subtitle">
						Deactivating the Media Assistant will also deactivate Ai Summarization and remove all Automated Ai Summary sections
						from all reports in the workspace.
					</Typography>
					<br />
					<Typography variant="subtitle">Would you like to deactivate the Media Assistant?</Typography>
				</div>
			)}
			cancelLabel="No"
			acceptLabel="Yes"
			open={isOpen}
			handleCancel={onCancel}
			handleAccept={onAccept}
		/>
	);
};

export default AiSummarizationModal;
