import DataGrid, { Column, FilterRow, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';

import { formatPagingInfoText, renderPlaintextCell } from '../../../../components/Dx/DxGridConstants';

const PreviewDataGrid = ({ tweets }) => {
	const handleRenderUserCell = ({ value }) => {
		return <div className="wrap">@{value}</div>;
	};

	return (
		<DataGrid
			id="rule-preview-table"
			showRowLines
			dataSource={tweets}
			showColumnLines={false}
			showBorders
			onContentReady={formatPagingInfoText}
		>
			<FilterRow visible applyFilter="auto" />

			<HeaderFilter visible />

			<Column
				width={150}
				caption="User"
				dataField="username"
				cssClass="cell cell--pad"
				cellRender={handleRenderUserCell}
				allowSorting={false}
				allowSearch
			>
				<HeaderFilter visible={false} />
			</Column>

			<Column caption="Tweet" dataField="text" cssClass="cell cell--pad" allowSearch cellRender={renderPlaintextCell}>
				<HeaderFilter allowSearch />
			</Column>

			<Column
				caption="Date"
				width={200}
				dataType="datetime"
				format="HH:mm dd/MM/yy"
				dataField="created_at"
				alignment="right"
				cssClass="cell cell--pad"
				allowHeaderFiltering={false}
				allowFiltering={false}
				allowSorting
				allowSearch={false}
			>
				<HeaderFilter allowSearch />
			</Column>

			<Paging defaultPageSize={10} />

			<Pager showPageSizeSelector allowedPageSizes={[5, 10, 20]} showInfo />
		</DataGrid>
	);
};

export default PreviewDataGrid;
