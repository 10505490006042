import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { useReducer, useState } from 'react';
import { components } from 'react-select';
import Save from '@mui/icons-material/Save';
import AsyncSelect from '@truescope-web/react/lib/components/form/AsyncSelect';
import Button from '@truescope-web/react/lib/components/form/Button';
import FileUpload from '@truescope-web/react/lib/components/form/FileUpload';
import ImageUploadCropper from '@truescope-web/react/lib/components/form/ImageUploadCropper';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import ValidationPlaceholder from '@truescope-web/react/lib/components/form/ValidationPlaceholder';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { getMediaTypeIcon } from '@truescope-web/react/lib/utils/mediaTypes';
import { validateObject } from '@truescope-web/react/lib/utils/validation';
import mediaTypesLookup from '@truescope/utils/lib/mediaItems/mediaTypesLookup';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import UploadType from '@truescope/utils/lib/lambda/upload/UploadType';

import { obfuscateFileName, uploadFile } from '../../../api/upload';
import { extractError } from '../../../components/Api';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { useConfig } from '../../../components/ConfigProvider';
import { searchAuthors } from '../../Entities/Authors/AuthorConstants';
import { searchSources } from '../../Entities/Sources/SourceConstants';
import { createMediaItem, mediaItemValidationResults } from './MediaItemConstants';
import mediaItemReducer, { getMediaItemInitialState, updateMediaItemState } from './mediaItemReducer';

const MediaItemBuilder = ({ onClose }) => {
	const [getDatahubApi] = useApiLookup();
	const { showSnackbar } = useSnackbar();
	const { config } = useConfig();
	const [mediaItem, dispatch] = useReducer(mediaItemReducer, getMediaItemInitialState());
	const [isLoading, setIsLoading] = useState(false);
	const [validationState, setValidationState] = useState();
	const printMediaType = config.mediaTypes.find((mediaType) => mediaType.name === mediaTypesLookup.print);
	const isPrintItem = mediaItem.channel?.media_type_id === printMediaType?.media_type_id || false;
	const selectedChannel = !isNullOrUndefined(mediaItem.channel) ? { label: mediaItem.channel.name, value: mediaItem.channel.id } : null;
	const selectedAuthor = !isNullOrUndefined(mediaItem.author) ? { label: mediaItem.author.name, value: mediaItem.author.name } : null;

	const handleSave = async () => {
		setIsLoading(true);
		try {
			const newValidationState = validateObject({ ...mediaItem, isPrintItem }, mediaItemValidationResults);
			setValidationState(newValidationState);
			if (!newValidationState.success) {
				return;
			}

			const saveMediaItem = cloneDeep(mediaItem);

			// Replace any new lines with HTML Break Lines
			if (!stringIsNullOrEmpty(saveMediaItem.body)) {
				saveMediaItem.body = saveMediaItem.body.replace(/\n/gm, '<br/>');
			}

			// Get the channels media_type_id
			saveMediaItem.media_type_id = saveMediaItem.channel.media_type_id;

			// Transform the chosen date time into ISO8601
			const itemTime = isNullOrUndefined(saveMediaItem.time) ? '00:00:00' : saveMediaItem.time;
			saveMediaItem.date = moment(saveMediaItem.date + ' ' + itemTime, 'YYYY-MM-DD HH:mm:ss')
				.utc()
				.toISOString();

			// We no longer need these
			delete saveMediaItem.time;
			delete saveMediaItem.channel.media_type_id;

			//todo: WHY IS THIS SENDING IMAGE INSTEAD OF IMAGE_URL
			await createMediaItem(getDatahubApi, saveMediaItem);

			showSnackbar('New item has been successfully added', snackbarVariants.success);
			onClose();
		} catch (e) {
			showSnackbar(`Failed to add new item - ${extractError(e)}`, snackbarVariants.error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeMediaItem = (propertyName, value) => {
		dispatch(
			updateMediaItemState({
				[propertyName]: value
			})
		);
	};

	const handleSearchSources = (queryText) => {
		if (queryText.length < 3) {
			return [];
		}
		return searchSources(getDatahubApi, queryText)
			.then((data) => {
				if (isNullOrUndefined(data)) {
					return [];
				}

				return data.map((item) => ({
					label: item.name,
					value: item.source_id,
					metadata: {
						media_type_id: item.media_type_id,
						name: config.mediaTypes.find(({ media_type_id }) => media_type_id === item.media_type_id).name
					}
				}));
			})
			.catch((e) => console.error('e', e));
	};

	const handleSearchAuthors = (authorText) => {
		if (authorText.length < 3) {
			return [];
		}
		return searchAuthors(getDatahubApi, authorText)
			.then((authors) =>
				(authors || []).map(({ name }) => ({
					label: name,
					value: name
				}))
			)
			.catch((e) => console.error('e', e));
	};

	const ChannelOption = (props) => {
		return (
			<components.Option {...props}>
				<div className="channel-option">
					<img className="media-type-icon" alt={props.data.label} src={getMediaTypeIcon(props.data.metadata.name)} />
					<div className="media-type-label">{props.data.label}</div>
				</div>
			</components.Option>
		);
	};

	const handleUploadImage = async ({ data, file }) => {
		try {
			return await uploadFile(getDatahubApi, obfuscateFileName(file), UploadType.mediaItemPicture, data);
		} catch (e) {
			const message = extractError(e);
			showSnackbar(message, snackbarVariants.error);
			throw new Error(message);
		}
	};

	return (
		<Grid className="media-item-builder" container spacing={2} padding={3}>
			<Grid item>
				<AsyncSelect
					placeholder="Select Channel"
					noOptionsMessage={() => 'Begin typing to search for Channel'}
					value={selectedChannel}
					onLoadOptions={handleSearchSources}
					onChange={(_e, _value, rawValue) =>
						handleChangeMediaItem(
							'channel',
							isNullOrUndefined(rawValue)
								? null
								: { name: rawValue.label, id: rawValue.value, media_type_id: rawValue.metadata.media_type_id }
						)
					}
					valueToOptionPath="value"
					optionToValuePath="mediaItem.channel"
					cacheOptions
					isClearable
					label="Channel"
					labelAbove
					components={{
						Option: ChannelOption
					}}
				/>
				<ValidationPlaceholder validationResult={validationState?.results?.channel} />
			</Grid>
			<Grid item>
				<AsyncSelect
					creatable
					createOptionPosition="first"
					minLength={3}
					placeholder="Select Author"
					noOptionsMessage={() => 'Begin typing to search for an Author'}
					value={selectedAuthor}
					onLoadOptions={handleSearchAuthors}
					onChange={(_e, _value, rawValue) =>
						handleChangeMediaItem('author', isNullOrUndefined(rawValue) ? null : { name: rawValue.label })
					}
					cacheOptions
					isClearable
					label="Author"
					labelAbove
				/>
			</Grid>

			<Grid item>
				<Inline horizontalAlignment={horizontalAlignment.center}>
					<TextField
						label="Date"
						labelAbove
						type="date"
						onChange={(_e, value) => handleChangeMediaItem('date', value)}
						value={mediaItem.date || ''}
					/>
					<TextField
						label="Time (optional)"
						labelAbove
						type="time"
						onChange={(_e, value) => handleChangeMediaItem('time', value)}
						value={mediaItem.time || ''}
					/>
				</Inline>
				<ValidationPlaceholder validationResult={validationState?.results?.date} />
			</Grid>

			<Grid item>
				<TextField
					label="Title"
					labelAbove
					type="text"
					onChange={(_e, value) => handleChangeMediaItem('title', value)}
					value={mediaItem.title || ''}
				/>
				<ValidationPlaceholder validationResult={validationState?.results?.title} />
			</Grid>

			{!isPrintItem && (
				<Grid item>
					<TextField
						label="Item URL"
						labelAbove
						type="text"
						onChange={(_e, value) => handleChangeMediaItem('source_url', value)}
						value={mediaItem.source_url || ''}
					/>
					<ValidationPlaceholder validationResult={validationState?.results?.source_url} />
				</Grid>
			)}

			<Grid item>
				<TextField
					label="Summary"
					labelAbove
					type="text"
					onChange={(_e, value) => handleChangeMediaItem('summary', value)}
					value={mediaItem.summary || ''}
				/>
			</Grid>

			<Grid item>
				<TextField
					label="Content"
					labelAbove
					type="text"
					minRows={8}
					multiline
					onChange={(_e, value) => handleChangeMediaItem('body', value)}
					value={mediaItem.body || ''}
				/>
			</Grid>

			<Grid item>
				<Inline horizontalAlignment={horizontalAlignment.center}>
					<ImageUploadCropper
						alt="Image"
						viewMode={2}
						url={mediaItem.image_url || ''}
						onUpload={handleUploadImage}
						onChange={(url) => handleChangeMediaItem('image_url', url)}
					/>
				</Inline>
			</Grid>

			{isPrintItem && (
				<>
					<Grid item>
						<Typography variant="h2">Additional Options</Typography>
						<Typography variant="body">Upload a PDF or image of your print item.</Typography>
					</Grid>
					<Grid item>
						<FileUpload onUpload={(data) => handleChangeMediaItem('print_clip', data)} acceptedFileTypes={'image/*,.pdf'} />
					</Grid>
					<Grid item>
						<ValidationPlaceholder validationResult={validationState?.results?.print_clip} />
					</Grid>
				</>
			)}

			<Grid item>
				<Inline horizontalAlignment={horizontalAlignment.right}>
					<Button variant="primary" onClick={handleSave} startIcon={<Save />} loading={isLoading}>
						Save
					</Button>
				</Inline>
			</Grid>
		</Grid>
	);
};

export default MediaItemBuilder;
