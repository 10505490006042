import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import SignOut from './views/Authentication/SignOut';
import ClientUser from './views/Clients/ClientUser/ClientUser';
import ClientUsers from './views/Clients/ClientUsers';
import Workspace from './views/Clients/Workspaces/Workspace/Workspace';
import Workspaces from './views/Clients/Workspaces/Workspaces';
import MediaItem from './views/Content/MediaItem/MediaItem';
import Scope from './views/Content/Scopes/Scope';
import Scopes from './views/Content/Scopes/Scopes';
import Search from './views/Content/Search/Search';
import Dashboard from './views/Dashboards/Dashboard';
import Authors from './views/Entities/Authors/Authors';
import Companies from './views/Entities/Companies/Companies';
import Entities from './views/Entities/Entities';
import People from './views/Entities/People/People';
import Source from './views/Entities/Sources/Source';
import Sources from './views/Entities/Sources/Sources';
import ErrorDetails from './views/Error/ErrorDetails';
import FeedRule from './views/Ingest/Feed/Rule';
import FeedRules from './views/Ingest/Feed/Rules';
import { feedsLookup } from './views/Ingest/Feed/constants';
import SocialHandlesManager from './views/SocialHandlesManager/SocialHandlesManager';
import AppLoader from '@truescope-web/react/lib/components/loading/AppLoader';
import IntervalView from './components/IntervalView';

const InternalRoutes = () => {
	const { setUserStorage, isInitialized } = useUserStorageContext();
	const location = useLocation();

	useEffect(() => {
		if (isInitialized && !location.pathname.startsWith('/media/feeds')) {
			setUserStorage((prev) => ({ ...prev, expandedRowKeys: [] }));
		}
	}, [isInitialized, location.pathname, setUserStorage]);

	if (!isInitialized) {
		return <AppLoader />;
	}

	return (
		<Routes>
			<Route path="/" element={<IntervalView />}>
				<Route path="/" element={<Navigate to="/media/search" replace />} />
				<Route path="/media" element={<Navigate to="/media/search" replace />} />
				<Route path="/media/search" exact element={<Search />} />
				<Route path="/media/items/:document_id" exact element={<MediaItem />} />
				<Route path="/media/feeds" exact element={<Scopes />} />
				<Route path="/media/feeds/create" exact element={<Scope create />} />
				<Route path="/media/feeds/clone/:scope_id" element={<Scope create />} />
				<Route path="/media/feeds/:scope_id" element={<Scope />} />
				<Route path="/clients/workspaces/:workspace_id" element={<Workspace />} />
				<Route path="/clients/workspaces" element={<Workspaces />} />
				<Route path="/clients/users/:user_id" element={<ClientUser />} />
				<Route path="/clients/users" element={<ClientUsers />} />
				<Route path="/entities/channels" exact element={<Sources />} />
				<Route path="/entities/channels/:source_id" element={<Source />} />
				<Route path="/entities/authors" exact element={<Authors />} />
				<Route path="/entities/people" exact element={<People />} />
				<Route path="/entities/companies" exact element={<Companies />} />
				<Route path="/entities" exact element={<Entities />} />
				<Route path="/dashboards/graph/:graph_id" element={<Dashboard />} />
				<Route path="/ingest/social-handles" element={<SocialHandlesManager />} />
				<Route path="/ingest/twitter/rules" exact element={<FeedRules feedId={feedsLookup.twitterPro} />} />
				<Route
					path="/ingest/twitter/rules/create"
					exact
					element={<FeedRule feedId={feedsLookup.twitterPro} create />}
					componentProps={{ create: true, feedId: feedsLookup.twitterPro }}
				/>
				<Route
					path="/ingest/twitter/rules/:feed_rule_id"
					exact
					element={<FeedRule feedId={feedsLookup.twitterPro} />}
					componentProps={{ feedId: feedsLookup.twitterPro }}
				/>
			</Route>
			<Route path="/signed-out" exact element={<SignOut />} />
			<Route path="/sign-out" exact element={<SignOut automatic />} />
			<Route path="/error" exact element={<ErrorDetails />} />
			<Route path="/error/:code" element={<ErrorDetails />} />
			<Route componentProps={{ code: '404' }} element={<ErrorDetails />} />
		</Routes>
	);
};
export default InternalRoutes;
