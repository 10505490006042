import { useCallback, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Tabs from '@truescope-web/react/lib/components/layout/Tabs';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import { extractError } from '../../../components/Api';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { useConfig } from '../../../components/ConfigProvider';
import Header from '../../../components/Header';
import { deserializeScope, getScope, getScopeOptions } from './ScopeConstants';
import ScopeDetailsTable from './ScopeDetailsTable';
import { getInitialScopeState, initializeScopeState, scopeReducer } from './ScopeReducer';
import ScopeWorkspacesTable from './ScopeWorkspacesTable';

const Scope = ({ create }) => {
	const [getDatahubApi] = useApiLookup();
	const { config } = useConfig();
	const params = useParams();
	const scopeId = parseInt(params?.scope_id, 10);
	const [scope, scopeDispatch] = useReducer(scopeReducer, getInitialScopeState());
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);
	const [scopeOptions, setScopeOptions] = useState([]);
	const [scopeWorkspaces, setScopeWorkspaces] = useState([]);

	const initialize = useCallback(async () => {
		try {
			setIsLoading(true);
			setErrorMessage(null);
			if (isNaN(scopeId)) {
				const { scopeOptions: newScopeOptions } = await getScopeOptions(getDatahubApi);
				scopeDispatch(initializeScopeState());
				setScopeOptions(newScopeOptions);
			} else {
				const {
					scope: existingScope,
					scopeOptions: existingScopeOptions,
					workspaces: existingScopeWorkspaces
				} = await getScope(getDatahubApi, scopeId);
				const deserializedScope = await deserializeScope(getDatahubApi, existingScope, config);
				if (create) {
					deserializedScope.name = `Copy Of ${deserializedScope.name}`;
					delete deserializedScope.scope_id;
				}
				scopeDispatch(initializeScopeState(deserializedScope));
				setScopeOptions(existingScopeOptions);
				setScopeWorkspaces(existingScopeWorkspaces);
			}
		} catch (e) {
			setErrorMessage(extractError(e));
		} finally {
			setIsLoading(false);
		}
	}, [scopeDispatch, setScopeOptions, scopeId, config, setScopeWorkspaces]);

	useEffect(() => {
		initialize();
	}, [initialize]);

	const renderScopesTab = () => {
		return (
			<Grid item>
				<Tabs
					items={[
						{
							label: 'Details',
							component: ScopeDetailsTable,
							props: {
								create,
								scope,
								scopeDispatch,
								scopeOptions
							}
						},
						{
							label: 'Workspaces',
							component: ScopeWorkspacesTable,
							props: {
								scopeWorkspaces,
								setScopeWorkspaces
							}
						}
					]}
				/>
			</Grid>
		);
	};

	const render = () => {
		if (isLoading) {
			return (
				<Grid item>
					<SkeletonWrapper />
				</Grid>
			);
		}

		if (!stringIsNullOrEmpty(errorMessage)) {
			return (
				<Grid item>
					<Typography variant="error">{errorMessage}</Typography>
				</Grid>
			);
		}

		if (isNullOrUndefined(scope)) {
			return <Grid item>Failed to load feed</Grid>;
		}

		return renderScopesTab();
	};

	return (
		<Content>
			<Header
				header={isLoading ? '...' : `${isNullOrUndefined(scopeId) && create ? 'Create' : scope.name}`}
				link={create ? 'clone' : ''}
			/>
			<Grid container>{render()}</Grid>
		</Content>
	);
};

export default Scope;
