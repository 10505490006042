import { useState } from 'react';
import Select from 'react-select';
import { Add, Clear, Done } from '@mui/icons-material';
import Button from '@truescope-web/react/lib/components/form/Button';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import { queryTableFields } from './MediaItemConstants';

const QueryOptions = ({ selectOptions, handleOnSave: handleOnQuerySave }) => {
	const [query, setQuery] = useState();
	const [queryValue, setQueryValue] = useState(null);
	const [enableSaveButton, setEnableSaveButton] = useState(false);
	const [showOptions, setShowOptions] = useState(false);

	const handleShowOptions = () => {
		setShowOptions(!showOptions);
	};

	const handleChangeOption = (option) => {
		const query = { name: option.label, query_id: option.metadata.query_id, score: 1 };
		setQuery(query);
		setEnableSaveButton(true);
		setQueryValue(option);
	};

	const handleOnSave = () => {
		handleOnQuerySave(query);
		handleCancel();
		setShowOptions(false);
	};

	const handleCancel = () => {
		setQueryValue(null);
		setQuery(null);
		setEnableSaveButton(false);
		setShowOptions(false);
	};

	return (
		<div className="query-options">
			{!showOptions ? (
				<Button tooltip="Add" fab={<Add />} onClick={handleShowOptions}></Button>
			) : (
				<div className="panel">
					<Inline>
						<Select
							className="select"
							options={selectOptions}
							placeholder={`Add a ${queryTableFields[0].label}`}
							autoFocus
							menuPosition="fixed"
							fullWidth
							value={queryValue}
							onChange={handleChangeOption}
						/>

						<div className="buttons">
							<Button
								className="save"
								variant={!enableSaveButton ? 'contained' : 'primary'}
								disabled={!enableSaveButton}
								onClick={handleOnSave}
							>
								<Done />
							</Button>
							<Button className="cancel" variant="contained" onClick={handleCancel}>
								<Clear />
							</Button>
						</div>
					</Inline>
				</div>
			)}
		</div>
	);
};

export default QueryOptions;
