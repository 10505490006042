import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';

import sortByString from '@truescope/utils/lib/strings/sortByString';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';

import { extractError, handleError } from '../../../components/Api';
import { appendAuditInfo } from '../../../components/Dx/DxGridConstants';
import { convertQueryConditionGroupsToElasticSearchQuery } from './QueryConditionGroupConstants';

const getSourcesByName = async (getDatahubApi, sourceNames, mediaTypesLookup) => {
	const api = await getDatahubApi();
	const { data } = await api.post(`/sources/v1`, {
		filterModel: {
			items: [
				{
					field: 'name',
					operator: 'equals',
					value: sourceNames
				}
			]
		},
		limit: 1000,
		offset: 0,
		sortModel: [
			{
				field: 'name',
				sort: 'asc'
			}
		]
	});
	return (data.items || []).map((datum) => ({
		label: !isNullOrUndefined(datum.media_type_id) ? `${datum.name} (${mediaTypesLookup[datum.media_type_id].name})` : datum.name,
		value: datum.source_id
	}));
};

const remapConditionFieldValues = async (getDatahubApi, condition, config) => {
	switch (condition.field) {
		case 'source_source_name':
			return {
				...condition,
				field: 'source_source_id',
				value: await getSourcesByName(getDatahubApi, condition.value, config.mediaTypesLookup)
			};
		default:
			return condition;
	}
};

const remapQueryConditionGroupFieldValues = async (getDatahubApi, queryConditionGroups, config) => {
	for (let x = 0; x < queryConditionGroups.length; x++) {
		for (let y = 0; y < queryConditionGroups[x].conditions.length; y++) {
			queryConditionGroups[x].conditions[y] = await remapConditionFieldValues(
				getDatahubApi,
				queryConditionGroups[x].conditions[y],
				config
			);
		}
	}
	return queryConditionGroups;
};

const getFilterConditionValue = (filterCondition) => {
	const [value1, value2] = arrayIsNullOrEmpty(filterCondition.function_parameters)
		? []
		: filterCondition.function_parameters.map((functionParameter) => functionParameter.value);

	switch (filterCondition.function_name) {
		case 'terms_set':
		case 'terms':
			return value1 || [];
		case 'query_string':
		case 'query_string_fields':
		case 'fuzzy':
		case 'exists':
		case 'nested':
		case 'not_exists':
		case 'prefix':
		case 'wildcard':
		case 'term':
			return value1?.[0];
		case 'range':
		case 'range_gte_lte':
			return { from: value1?.[0], to: value2?.[0] };
		case 'range_gt':
		case 'range_gte':
			return { from: value1?.[0] };
		case 'range_lt':
		case 'range_lte':
			return { to: value1?.[0] };
		default:
			throw new Error(`unknown function_name '${filterCondition.function_name}'`);
	}
};

const convertOperatorToBoolArrayKey = (operator) => {
	switch (operator) {
		case 'And':
			return 'must';
		case 'Or':
			return 'should';
		case 'Not':
			return 'must_not';
		default:
			throw new Error(`unknown operator '${operator}'`);
	}
};

const convertFilterConditionToCondition = (filterCondition, id) => {
	return {
		value: !stringIsNullOrEmpty(filterCondition.function_name) ? getFilterConditionValue(filterCondition) : null,
		field: filterCondition.field_name,
		filterFunction: filterCondition.function_name,
		id
	};
};

const convertFilterGroupsToQueryConditionGroups = (filterGroups) =>
	(filterGroups || []).map((filterGroup, index) => {
		const condition = {
			id: index,
			conditionsOperator: convertOperatorToBoolArrayKey(filterGroup.group_operator),
			conditions: (filterGroup.filter_conditions || []).map((filterCondition, index) =>
				convertFilterConditionToCondition(filterCondition, index)
			)
		};

		if (index > 0) {
			condition.groupOperator = convertOperatorToBoolArrayKey(filterGroup.group_operator_prefix);
		}

		return condition;
	});

/**
 * validation for query condition
 * if query condition contains an empty value or '*' throw error
 */
const queryConditionContainsInvalidValue = (queryConditionGroups) =>
	(queryConditionGroups || []).some(({ conditions }) =>
		(conditions || []).some((condition) => {
			if (isNullOrUndefined(condition.value)) {
				return true;
			}
			if (Array.isArray(condition.value)) {
				return arrayIsNullOrEmpty(condition.value);
			}
			if (typeof condition.value === 'string') {
				const conditionValue = condition.value.trim();
				return stringIsNullOrEmpty(conditionValue) || conditionValue === '*';
			}
			return false;
		})
	);

export const deserializeScope = async (getDatahubApi, { filter_groups, elasticsearch_query, ...scope }, config) => {
	if (isNullOrUndefined(scope.queryConditionGroups) && !isNullOrUndefined(filter_groups)) {
		try {
			//the elastic query needs to be an object, not a string, so we can use it in the search preview
			scope.elasticsearch_query = !stringIsNullOrEmpty(elasticsearch_query) ? JSON.parse(elasticsearch_query) : null;
		} catch (e) {
			console.warn(`failed to convert scope ${scope.scope_id} elastic query, skipping`, elasticsearch_query);
			scope.elasticsearch_query = null;
		}

		try {
			scope.queryConditionGroups = convertFilterGroupsToQueryConditionGroups(filter_groups);
		} catch (e) {
			const message = `failed to convert scope ${scope.scope_id} filter groups - ${e.message}`;
			console.error(message, filter_groups, scope, elasticsearch_query);
			throw new Error(message);
		}

		try {
			scope.queryConditionGroups = await remapQueryConditionGroupFieldValues(getDatahubApi, scope.queryConditionGroups, config);
		} catch (e) {
			console.warn(`failed to remap scope ${scope.scope_id} - ${e.message}`, scope.queryConditionGroups);
		}

		try {
			//in some cases, the convertion might result in bad/empty conditions. so we allow it and continue
			scope.elasticsearch_query = convertQueryConditionGroupsToElasticSearchQuery(
				scope.queryConditionGroups,
				config.searchFieldVariants
			);
		} catch (e) {
			scope.elasticsearch_query = null;
			console.warn(`failed to convert scope ${scope.scope_id} filter groups - ${e.message}`, scope.queryConditionGroups);
		}
	}

	if (!arrayIsNullOrEmpty(scope.queryConditionGroups) && !stringIsNullOrEmpty(scope.queryConditionGroups[0].groupOperator)) {
		delete scope.queryConditionGroups[0].groupOperator;
	}

	return scope;
};

/**
 * gets an existing scope
 * @param {*} param0
 * @param {*} scope_id
 */
export const getScope = (getDatahubApi, scopeId) => {
	return getDatahubApi().then((api) =>
		api.get(`/scopes/v1/${scopeId}`).then(({ data }) => {
			const { message, ...scopeData } = data;
			if (!stringIsNullOrEmpty(message)) {
				throw new Error(message);
			}
			return scopeData;
		})
	);
};

/**
 * gets the workspaces for a given scope
 * @param {*} param0
 * @param {*} scope_id
 * @returns
 */
export const getWorkspaces = (getDatahubApi, scope_id) => {
	return getDatahubApi().then((api) =>
		api
			.get(`/scopes/v1/${scope_id}/workspaces`)
			.then(({ data }) => data)
			.catch((e) => handleError(`Failed to get feed`, e))
	);
};

/**
 * gets all scopes
 */
export const getScopes = (getDatahubApi, params = {}) => {
	return getDatahubApi().then((api) =>
		api.post(`/scopes/v1/`, params).then(({ data }) => {
			const { message, ...other } = data;
			if (!stringIsNullOrEmpty(message)) {
				throw new Error(message);
			}
			return other;
		})
	);
};

/**
 * creates a new empty scope
 */
export const getScopeOptions = (getDatahubApi) => {
	return getDatahubApi().then((api) =>
		api.get(`/scopes/v1/create`).then(({ data }) => {
			const { message, scopeOptions } = data;
			if (!stringIsNullOrEmpty(message)) {
				throw new Error(message);
			}
			return { scopeOptions };
		})
	);
};

/**
 * saves a scope
 */
export const saveScope = (
	getDatahubApi,
	{ scope_id, name, queryConditionGroups, parent_scope_id, enable_ingest_backfill, enable_item_matching },
	searchFieldVariants
) => {
	const scope = {
		name,
		queryConditionGroups,
		parent_scope_id: isNullOrUndefined(parent_scope_id) ? undefined : parent_scope_id,
		enable_ingest_backfill,
		enable_item_matching
	};
	if (enable_item_matching) {
		if (arrayIsNullOrEmpty(queryConditionGroups)) {
			throw new Error(`Must provide at least 1 query condition before saving with enable_item_matching=true`);
		}
		if (queryConditionContainsInvalidValue(queryConditionGroups)) {
			throw new Error(`Your search conditions are not complete and therefore cannot be saved`);
		}
		scope.elasticsearch_query = convertQueryConditionGroupsToElasticSearchQuery(queryConditionGroups, searchFieldVariants);
	}

	//we reorder sources by their name, to make it easier to navigate huge lists of sources
	(queryConditionGroups || []).forEach((conditionGroup) => {
		conditionGroup.conditions
			.filter(
				(condition) =>
					condition.field === 'source_source_name' ||
					(condition.field === 'source_source_id' && !arrayIsNullOrEmpty(condition.value))
			)
			.forEach((condition) => {
				condition.value = sortByString(condition.value, 'label');
			});
	});

	return getDatahubApi().then((api) =>
		(isNullOrUndefined(scope_id) ? api.put('/scopes/v1', scope) : api.patch(`/scopes/v1/${scope_id}`, scope)).then(({ data }) => data)
	);
};

export const deleteScope = (getDatahubApi, id) => {
	return getDatahubApi().then((api) => api.delete(`/scopes/v1/${id}`).then(({ data }) => data));
};

const deserializeScopes = (scopes) => {
	return scopes.map((scope) => appendAuditInfo(scope));
};

export const createScopeDataSource = (getDatahubApi) => {
	const cache = {
		data: null,
		totalCount: 0
	};

	const ds = {
		cache,
		store: new CustomStore({
			load: (_loadOptions) => {
				return getDatahubApi()
					.then((api) =>
						api.post('/scopes/v1').then(
							({ data }) => {
								return {
									data: deserializeScopes(data.items),
									totalCount: data.totalCount
								};
							},
							(fail) => {
								console.error('failed to get feeds', fail);
								return [];
							}
						)
					)
					.catch((ex) => {
						console.error('failed search for feeds', ex);
						return {
							data: [],
							totalCount: 0,
							elasticQuery: null
						};
					});
			},
			onLoaded: (e) => {
				cache.data = e.data;
				cache.totalCount = e.totalCount;
			}
		})
	};

	return ds;
};

/**
 * save the percolated scope items to a workspace
 * @param {*} param0
 * @param {*} workspace_id
 * @param {*} scope_id
 * @param {*} date
 */
export const addPercolatedScopeItemsToWorkspace = async (getDatahubApi, workspace_id, scope_id, dateFrom) => {
	return getDatahubApi()
		.then((api) =>
			api.post(`/workspaces/v1/${workspace_id}/add-scope-items`, {
				scope_id,
				date: moment(dateFrom, 'YYYY-MM-DD').utc().toISOString()
			})
		)
		.then(({ data }) => data)
		.catch((e) => ({ message: `Failed to add feed items to workspace - ${extractError(e)}` }));
};

/**
 * save new items to a scope
 * @param {*} param0
 * @param {*} workspace_id
 * @param {*} scope_id
 * @param {*} date
 */
export const addItemsToScope = async (getDatahubApi, scope_id, date, workspace_ids) => {
	return getDatahubApi()
		.then((api) =>
			api.post(`/scopes/v1/${scope_id}/add-items`, {
				date: moment(date, 'YYYY-MM-DD').utc().toISOString(),
				workspace_ids
			})
		)
		.then(({ data }) => data)
		.catch((e) => ({ message: `Failed to add items to feed - ${extractError(e)}` }));
};

/**
 * Gets counts matched to Feed
 * @param {*} getDatahubApi - the API to call
 * @param {number} scope_id -
 * @param {string} date
 * @param {number|undefined} workspace_id
 * @returns
 */
export const getScopeItemCounts = async (getDatahubApi, scope_id, date, workspace_id) => {
	return getDatahubApi().then((api) =>
		api
			.post(`/scopes/v1/${scope_id}/counts`, {
				publication_date_from: moment(date, 'YYYY-MM-DD').utc().toISOString(),
				workspace_id
			})
			.then(({ data }) => data)
			.catch((e) => ({ message: `Failed to retrieve item counts - ${extractError(e)}` }))
	);
};
