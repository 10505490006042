import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import ThemeProvider from '@mui/styles/ThemeProvider';
import { LicenseInfo as MuiProLicenseInfo } from '@mui/x-license-pro/utils/licenseInfo';
import UserStorageProvider from '@truescope-web/react/lib/components/UserStorageProvider';
import { SheetProvider as SheetProviderV2 } from '@truescope-web/react/lib/components/modal/SheetV2';
import { SnackbarProvider } from '@truescope-web/react/lib/components/modal/Snackbar';
import Routes from './Routes';
import { ApiLookupProvider } from './components/ApiLookupProvider';
import { ConfigProvider } from './components/ConfigProvider';
import { getAuthState } from './components/GoogleAuth/constants';
import './scss/app.scss';
import theme from './theme';

/*
initialize google tag manager
*/
TagManager.initialize({
	gtmId: 'GTM-W368R4B'
});

//initialize mui pro
MuiProLicenseInfo.setLicenseKey(
	'dd87ef68b39ca535700dc98a799c2281Tz0xMDQwMDEsRT0xNzY1NDExMDk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
);

/*
create the root app
*/
const App = () => {
	const { user } = getAuthState();

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<SheetProviderV2>
					<ApiLookupProvider>
						<UserStorageProvider storageKey={user?.sub}>
							<ConfigProvider>
								<SnackbarProvider>
									<div className="app">
										<Router>
											<Routes />
										</Router>
									</div>
								</SnackbarProvider>
							</ConfigProvider>
						</UserStorageProvider>
					</ApiLookupProvider>
				</SheetProviderV2>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default App;
