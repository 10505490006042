import { useState } from 'react';
import Select from '@truescope-web/react/lib/components/form/Select';
import Switch from '@truescope-web/react/lib/components/form/Switch';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import TablePanel from '@truescope-web/react/lib/components/layout/TablePanel';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { audienceOverrideTypeOptions, audienceOverrideTypes, audiencesFields, getDefaultAudience, updateSource } from './SourceConstants';

const Audiences = ({ audiences, audienceFields, setAudienceFields, source }) => {
	const [getDatahubApi] = useApiLookup();
	const [isOverrideAudienceLoading, setIsOverrideAudienceLoading] = useState(false);
	const isValueOverride = audienceFields?.overrideType === audienceOverrideTypes.value;

	const handleToggleOverrideAudience = (e) => {
		const overrideAudience = Boolean(e.target.checked);
		setAudienceFields((prev) => ({ ...prev, overrideAudience: overrideAudience }));
		if (overrideAudience) {
			setAudienceFields((prev) => ({
				...prev,
				overrideType: audienceOverrideTypes.value,
				audienceValue: getDefaultAudience(audiences),
				audienceMultiplier: null
			}));
		} else {
			setAudienceFields((prev) => ({ ...prev, audienceValue: null, audienceMultiplier: null }));
			handleSaveAudienceOverride(null, null, null);
		}
	};

	const handleOverrideTypeChange = (_e, newValue) => {
		setAudienceFields((prev) => ({ ...prev, overrideType: newValue }));
		if (newValue === audienceOverrideTypes.value) {
			setAudienceFields((prev) => ({ ...prev, audienceValue: getDefaultAudience(audiences) }));
			handleSaveAudienceOverride(null, getDefaultAudience(audiences), null);
		} else {
			setAudienceFields((prev) => ({ ...prev, audienceMultiplier: 1 }));
			handleSaveAudienceOverride(null, null, 1);
		}
	};

	const handleSaveAudienceOverride = (e, audienceValue, audienceMultiplier) => {
		const updates = {
			override_audience: audienceValue !== undefined ? audienceValue : audienceFields?.audienceValue,
			audience_multiplier: audienceMultiplier !== undefined ? audienceMultiplier : audienceFields?.audienceMultiplier
		};
		setIsOverrideAudienceLoading(true);
		updateSource(getDatahubApi, source, updates).finally(() => setIsOverrideAudienceLoading(false));
	};

	const handleAudienceChange = (e) => {
		if (isValueOverride) {
			setAudienceFields((prev) => ({ ...prev, audienceValue: e.target.value, audienceMultiplier: null }));
		} else {
			setAudienceFields((prev) => ({ ...prev, audienceMultiplier: e.target.value, audienceValue: null }));
		}
	};

	return (
		<Grid container>
			<Grid item>
				<Switch
					value={audienceFields?.overrideAudience}
					onChange={handleToggleOverrideAudience}
					disabled={isOverrideAudienceLoading}
					label="Override audience"
				/>
			</Grid>
			{audienceFields?.overrideAudience ? (
				<Grid item>
					<Inline>
						<Select
							value={audienceFields?.overrideType}
							options={audienceOverrideTypeOptions}
							onChange={handleOverrideTypeChange}
							disabled={isOverrideAudienceLoading}
							label="Override type"
							labelAbove
							menuPlacement="top"
						/>
						<TextField
							value={isValueOverride ? audienceFields?.audienceValue : audienceFields?.audienceMultiplier}
							onChange={handleAudienceChange}
							onBlur={handleSaveAudienceOverride}
							disabled={isOverrideAudienceLoading}
							label={`Audience ${isValueOverride ? 'value' : 'multiplier'}`}
							labelAbove
						/>
					</Inline>
				</Grid>
			) : (
				<Grid item>
					<TablePanel items={audiences} fields={audiencesFields} />
				</Grid>
			)}
		</Grid>
	);
};

export default Audiences;
