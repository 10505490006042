import cloneDeep from 'lodash.clonedeep';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Collapse from '@mui/material/Collapse';
import Button from '@truescope-web/react/lib/components/form/Button';
import Select from '@truescope-web/react/lib/components/form/Select';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import { extractError } from '../../components/Api';
import { useApiLookup } from '../../components/ApiLookupProvider';
import Header from '../../components/Header';
import SocialHandle from './SocialHandle';
import { search } from './api';
import { countryOptions, getDefaultLocation, getDefaultSocialNetworkOption, getUrlParams, socialNetworkOptions } from './constants';

const SocialHandlesManager = () => {
	const urlLocation = useLocation();
	const [keyword, setKeyword] = useState('');
	const [location, setLocation] = useState(getDefaultLocation());
	const [socialHandles, setSocialHandles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [socialNetwork, setSocialNetwork] = useState(getDefaultSocialNetworkOption());
	const { showSnackbar } = useSnackbar();
	const disableSearch = isLoading || stringIsNullOrEmpty(keyword) || isNullOrUndefined(location);
	const [getDatahubApi] = useApiLookup();

	const searchSocialHandles = useCallback(
		async (socialNetwork, keyword, location) => {
			try {
				setIsLoading(true);
				const searchString = `${socialNetwork.metadata} ${keyword}`;
				const data = await search(getDatahubApi, searchString, location?.value, 'English');
				setSocialHandles(data.map((handle) => ({ location, ...handle })));
			} catch (e) {
				console.error(`search timeout - ${extractError(e)}`, e);
				showSnackbar('A timeout occurred in a third party service, please wait before trying again', snackbarVariants.warning);
			} finally {
				setIsLoading(false);
			}
		},
		[setIsLoading, setSocialHandles, getDatahubApi]
	);

	const handleSearch = useCallback(() => {
		if (disableSearch) {
			return;
		}
		searchSocialHandles(socialNetwork, keyword, location);
	}, [disableSearch, socialNetwork, keyword, location, searchSocialHandles]);

	useEffect(() => {
		const urlParams = getUrlParams(urlLocation);
		if (isNullOrUndefined(urlParams)) {
			return;
		}
		setKeyword(urlParams.keyword);
		setLocation(urlParams.location);
		setSocialNetwork(urlParams.socialNetwork);
		searchSocialHandles(urlParams.socialNetwork, urlParams.keyword, urlParams.location);
	}, [urlLocation.search, setKeyword, setLocation, setSocialNetwork, searchSocialHandles]);

	const handleSocialHandleChange = (socialHandle) => {
		const existingHandleIndex = socialHandles.findIndex(({ url }) => url === socialHandle.url);
		if (existingHandleIndex < 0) {
			showSnackbar(`cannot find social handle ${socialHandle.url} in social handles`);
			setSocialHandles([]);
			return;
		}
		const updatedSocialHandles = [...socialHandles];
		updatedSocialHandles[existingHandleIndex] = cloneDeep(socialHandle);
		setSocialHandles(updatedSocialHandles);
	};

	return (
		<Content size="sm">
			<Grid container spacing={2}>
				<Grid item>
					<Header header="Social Handles" />
				</Grid>
				<Grid item>
					<Grid container>
						<Grid item>
							<TextField
								labelAbove
								label="Search term"
								placeholder="Enter a search term to find social media accounts"
								value={keyword}
								onChange={(_e, value) => setKeyword(value)}
								onEnter={handleSearch}
								disabled={isLoading}
							/>
						</Grid>
						<Grid item md={6}>
							<Select
								labelAbove
								label="Social Network"
								options={socialNetworkOptions}
								value={socialNetwork}
								onChange={(_e, _value, rawValue) => setSocialNetwork(rawValue)}
								disabled={isLoading}
							/>
						</Grid>
						<Grid item md={6}>
							<Select
								labelAbove
								label="Location"
								options={countryOptions}
								value={location}
								onChange={(_e, _value, rawValue) => setLocation(rawValue)}
								disabled={isLoading}
							/>
						</Grid>
						<Grid item>
							<Inline horizontalAlignment={horizontalAlignment.right}>
								<Button variant="primary" onClick={handleSearch} disabled={disableSearch} loading={isLoading}>
									Search Handles
								</Button>
							</Inline>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Collapse in={!isLoading && !arrayIsNullOrEmpty(socialHandles)}>
						<Grid container>
							{socialHandles.map((socialHandle) => (
								<Grid item key={socialHandle.url}>
									<SocialHandle socialHandle={socialHandle} onChange={handleSocialHandleChange} />
								</Grid>
							))}
						</Grid>
					</Collapse>
				</Grid>
			</Grid>
		</Content>
	);
};

export default SocialHandlesManager;
