import TreeList, { Column, ColumnChooser, FilterRow, HeaderFilter, Pager, Paging, Selection } from 'devextreme-react/tree-list';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Add from '@mui/icons-material/Add';
import { useUserStorageContext } from '@truescope-web/react/lib/components/UserStorageProvider';
import Button from '@truescope-web/react/lib/components/form/Button';
import TextField from '@truescope-web/react/lib/components/form/TextField';
import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline from '@truescope-web/react/lib/components/layout/Inline';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import SkeletonWrapper from '@truescope-web/react/lib/components/loading/SkeletonWrapper';
import Alert from '@truescope-web/react/lib/components/modal/Alert';
import Sheet from '@truescope-web/react/lib/components/modal/SheetV2';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import { extractError } from '../../../components/Api';
import { useApiLookup } from '../../../components/ApiLookupProvider';
import { renderNameTreeNode } from '../../../components/Dx/DxGridConstants';
import Header from '../../../components/Header';
import MoreOptionsMenu from '../../../components/Widgets/MoreOptionsMenu';
import AddItemsToScope from './AddItems/AddItemsToScope';
import { createScopeDataSource, deleteScope } from './ScopeConstants';

const modalStates = {
	rename: 1,
	delete: 2,
	addItems: 3
};

const Scopes = () => {
	const [getDatahubApi] = useApiLookup();
	const navigate = useNavigate();
	const { userStorage, setUserStorage } = useUserStorageContext();
	const [dataTrigger, setDataTrigger] = useState(() => uuidv4());
	const treeList = useRef(null);
	const { showSnackbar } = useSnackbar();
	const [modalState, setModalState] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const dataSource = useMemo(() => {
		setIsLoading(true);
		const ds = createScopeDataSource(getDatahubApi);
		setIsLoading(false);
		return ds;
	}, [getDatahubApi, dataTrigger, setIsLoading]);

	const handleCloseModal = () => {
		setModalState(null);
	};

	const handleAcceptDelete = async () => {
		handleCloseModal();
		try {
			treeList.current._instance.beginCustomLoading();
			await deleteScope(getDatahubApi, modalState.scope.scope_id);
			setDataTrigger(uuidv4());
		} catch (e) {
			console.error(`delete scope failed - ${extractError(e)}`);
		} finally {
			treeList.current._instance.endCustomLoading();
		}
	};

	const handleAcceptRename = async () => {
		handleCloseModal();
		try {
			treeList.current._instance.beginCustomLoading();
			const api = await getDatahubApi();
			await api.patch(`/scopes/v1/${modalState.scope.scope_id}/field/name`, { value: modalState.scope.name });
			setDataTrigger(uuidv4());
		} catch (e) {
			const message = `rename scope failed - ${extractError(e)}`;
			console.error(message, e);
			showSnackbar(message, snackbarVariants.error);
		} finally {
			treeList.current._instance.endCustomLoading();
		}
	};

	const handleItemsAddedToScope = (scope, addedItemsCount) => {
		handleCloseModal();
		treeList.current._instance.beginCustomLoading();
		scope.item_count_month += addedItemsCount;
		treeList.current._instance.endCustomLoading();
	};

	const handleClone = (scope) => {
		navigate(`/media/feeds/clone/${scope.scope_id}`);
	};

	const handleEdit = (scope) => {
		view(scope);
	};

	const handleCreateScope = (_e) => {
		navigate('/media/feeds/create');
	};

	const view = ({ scope_id }) => {
		navigate(`/media/feeds/${scope_id}`);
	};

	const storeRowKey = (key) => {
		const expandedRowKeys = userStorage.expandedRowKeys || [];
		if (!expandedRowKeys.includes(key)) {
			expandedRowKeys.push(key);
			setUserStorage((prev) => ({ ...prev, expandedRowKeys }));
		}
	};

	const unstoreRowKey = (key) => {
		const expandedRowKeys = userStorage.expandedRowKeys || [];
		if (expandedRowKeys.includes(key)) {
			const rowKeyIndex = expandedRowKeys.indexOf(key);
			expandedRowKeys.splice(rowKeyIndex, 1);
			setUserStorage((prev) => ({ ...prev, expandedRowKeys }));
		}
	};

	const handleRenameChange = useCallback(
		(e) => {
			setModalState((prev) => {
				prev.scope.name = e.target.value;
				return { ...prev };
			});
		},
		[setModalState]
	);

	const renderDateCell = ({ data }) => {
		return (
			<div className="wrap">
				<Typography>{data.date_updated_string}</Typography>
			</div>
		);
	};

	const renderMoreOptionsCell = ({ data }) => {
		return (
			<div className="wrap">
				<MoreOptionsMenu
					options={[
						{
							label: 'Edit',
							onClick: () => handleEdit(data)
						},
						{
							label: 'Rename',
							onClick: () => setModalState({ scope: data, type: modalStates.rename })
						},
						{
							label: 'Duplicate',
							onClick: () => handleClone(data)
						},
						{
							label: 'Add items',
							onClick: () => setModalState({ scope: data, type: modalStates.addItems }),
							disabled: !data.enable_item_matching
						},
						{
							label: 'Remove',
							onClick: () => setModalState({ scope: data, type: modalStates.delete })
						}
					]}
					size="xs"
				/>
			</div>
		);
	};

	const renderScopesTable = () => {
		const expandedRowKeys = userStorage.expandedRowKeys || [];
		return (
			<div className="scopes-table">
				<TreeList
					autoExpandAll={false}
					ref={treeList}
					dataSource={dataSource}
					showRowLines={true}
					showColumnLines={false}
					showBorders={true}
					dataStructure="plain"
					keyExpr="scope_id"
					parentIdExpr="parent_scope_id"
					wordWrapEnabled={true}
					id="scopes"
					onRowCollapsed={({ key }) => unstoreRowKey(key)}
					onRowExpanded={({ key }) => storeRowKey(key)}
					defaultExpandedRowKeys={expandedRowKeys}
				>
					<FilterRow visible={true} applyFilter="auto" />

					<HeaderFilter visible={true} />
					<Selection mode="single" allowSelectAll={false} />
					<ColumnChooser enabled={false} />

					<Column
						dataField="name"
						caption="Feed Name"
						dataType="string"
						cellRender={(e) =>
							renderNameTreeNode(e, {
								viewItem: view
							})
						}
						allowFiltering={true}
						allowSearch={true}
						allowSorting={true}
					></Column>

					<Column dataField="date_updated" caption="Last Updated" dataType="date" cellRender={renderDateCell} />
					<Column
						dataField="id"
						width={60}
						cssClass="cell cell--center"
						alignment="center"
						caption=""
						cellRender={renderMoreOptionsCell}
						allowFiltering={false}
						allowSearch={false}
						allowSorting={false}
					/>

					<Paging defaultPageSize={3} />

					<Pager showPageSizeSelector={true} allowedPageSizes={[10, 20, 50]} showInfo={true} />
				</TreeList>
			</div>
		);
	};

	const renderRenameAlert = useCallback(
		() => (
			<Alert
				title="Rename"
				message={
					<Inline>
						<TextField value={modalState?.scope?.name || ''} onChange={handleRenameChange} placeholder="Enter a new name" />
					</Inline>
				}
				open={modalState?.type === modalStates.rename}
				handleCancel={handleCloseModal}
				handleAccept={handleAcceptRename}
			/>
		),
		[modalState, handleRenameChange]
	);

	return (
		<>
			<Content>
				{isLoading ? (
					<SkeletonWrapper />
				) : (
					<>
						<Header header="Feeds" link="scopes">
							<Button
								fab={<Add />}
								variant="primary"
								onClick={handleCreateScope}
								aria-label="Create"
								disabled={isLoading}
							></Button>
						</Header>
						<Grid container>
							<Grid item>{renderScopesTable()}</Grid>
						</Grid>
					</>
				)}
			</Content>
			{renderRenameAlert()}
			<Alert
				title="Delete Feed"
				message="Are you sure you want to delete this scope?"
				cancelLabel="No"
				acceptLabel="Yes"
				open={modalState?.type === modalStates.delete}
				handleCancel={handleCloseModal}
				handleAccept={handleAcceptDelete}
			/>
			<Sheet isOpen={modalState?.type === modalStates.addItems} onClose={handleCloseModal}>
				<AddItemsToScope
					onClose={handleCloseModal}
					scope_id={modalState?.scope?.scope_id}
					onItemsAdded={(addedItemsCount) => handleItemsAddedToScope(modalState?.scope, addedItemsCount)}
				/>
			</Sheet>
		</>
	);
};

export default Scopes;
