import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';

export const getInputType = (field) => {
	switch (field) {
		case 'influence':
		case 'audience':
		case 'duration':
		case 'sentiment_magnitude':
		case 'sentiment_score':
		case 'word_count':
		case 'source_source_online_rank':
			return 'number';
		case 'found_date':
		case 'item_date':
		case 'modified_date':
		case 'publication_date':
			return 'date';
		default:
			throw new Error(`unsupported range '${field}'`);
	}
};

export const getRangeType = (filterFunction) => {
	switch (filterFunction) {
		case 'range_gt':
		case 'range_gte':
			return 'from';
		case 'range_lt':
		case 'range_lte':
			return 'to';
		default:
			throw new Error(`unsupported filter function '${filterFunction}'`);
	}
};

const hasOptionProperties = (option) =>
	Object.prototype.hasOwnProperty.call(option, 'label') && Object.prototype.hasOwnProperty.call(option, 'value');

export const isOption = (options) => {
	if (isNullOrUndefined(options)) {
		return false;
	}
	if (Array.isArray(options)) {
		return options.every((option) => hasOptionProperties(option));
	}
	return hasOptionProperties(options);
};
