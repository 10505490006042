import TreeList, { Column, ColumnChooser, FilterRow, HeaderFilter, Pager, Paging, Selection } from 'devextreme-react/tree-list';
import moment from 'moment';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';
import { useMemo, useState } from 'react';
import { useRef } from 'react';
import { BiGhost } from 'react-icons/bi';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress, Link, Tooltip } from '@mui/material';
import Notification from '@truescope-web/react/lib/components/layout/Notification';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Sheet from '@truescope-web/react/lib/components/modal/SheetV2';
import { snackbarVariants, useSnackbar } from '@truescope-web/react/lib/components/modal/Snackbar';
import ToolbarButton from '@truescope-web/react/lib/components/widgets/ToolbarButton';
import isProduction from '@truescope/utils/lib/helpers/isProduction';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import { extractError } from '../../components/Api';
import { useApiLookup } from '../../components/ApiLookupProvider';
import { useConfig } from '../../components/ConfigProvider';
import { createDataSource } from '../../components/Dx/DxGridConstants';
import { getAuthState } from '../../components/GoogleAuth/constants';
import Header from '../../components/Header';
import MoreOptionsMenu from '../../components/Widgets/MoreOptionsMenu';
import ClientUser from './ClientUser/ClientUser';
import { appUrl, canImpersonateClientUser, createImpersonation, deleteImpersonation } from './ClientUserConstants';
import { removeInvite, sendInvites } from './Workspaces/Workspace/Users/api';
import { workspaceStatesLookup } from '@/constants';

const datahubUrl = isProduction() ? 'datahub.truescope.com' : 'datahub.dev2.truescope.cloud';

const ClientUsers = () => {
	const treeListRef = useRef();
	const { config } = useConfig();
	const [getDatahubApi] = useApiLookup();
	const [userData, setUserData] = useState();
	const { showSnackbar } = useSnackbar();
	const [cacheBust, setCacheBust] = useState(uuidv4());
	const dataSource = useMemo(
		() =>
			createDataSource({
				getDatahubApi,
				requestPath: '/client-users/v1',
				useWhereClauses: true,
				key: 'key'
			}),
		[getDatahubApi, cacheBust]
	);
	const { user } = getAuthState();
	const canImpersonate = canImpersonateClientUser(user.email);
	const [isLoadingRecordId, setIsLoadingRecordId] = useState(null);

	const handleExport = async () => {
		try {
			const items = dataSource.cache.data;

			const columns = [
				'workspace_name',
				'role_name',
				'expiration',
				'membership',
				'market_name',
				'role_name',
				'email',
				'client_name',
				'user_name'
			];

			//key the users to the username
			const userNameLookup = items.reduce((lookup, row) => {
				if (!isNullOrUndefined(row.email)) {
					lookup[row.email] = row;
				}
				return lookup;
			}, {});

			//grab each workspace user record to export
			const data = items.reduce((rows, row) => {
				if (!stringIsNullOrEmpty(row.parent)) {
					const user = userNameLookup[row.parent];
					rows.push(
						columns.reduce((obj, column) => {
							obj[column] = isNullOrUndefined(user[column]) ? row[column] : user[column];
							return obj;
						}, {})
					);
				}
				return rows;
			}, []);

			const csv = Papa.unparse({ data, fields: columns });
			if (csv == null) {
				throw new Error('couldnt parse data');
			}

			//attach data to an anchor and download it
			const blob = new Blob([csv]);
			const a = window.document.createElement('a');
			a.href = window.URL.createObjectURL(blob, { type: 'text/csv' });
			a.download = `datahub_user_export_${moment().format('DDMMYY-HHmmss')}.csv`;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		} catch (e) {
			showSnackbar(`Failed to export grid - ${e.message}`, snackbarVariants.error);
		}
	};

	const handleCopyToClipboardClick = (data) => {
		const guid = data.guid;
		if (stringIsNullOrEmpty(guid)) {
			showSnackbar('Failed to copy invite link', snackbarVariants.error);
			return;
		}

		const link = isProduction() ? `https://app.truescope.com/join/${guid}` : `https://app.dev2.truescope.cloud/join/${guid}`;

		navigator.clipboard.writeText(link);
		showSnackbar('Copied to Clipboard', snackbarVariants.success);
	};

	const handleRemoveInvitationClick = async ({ workspace_id, parent: email }) => {
		try {
			treeListRef.current._instance.beginCustomLoading();
			const { message } = await removeInvite(getDatahubApi, workspace_id, email);
			if (!stringIsNullOrEmpty(message)) {
				throw new Error(message);
			}
			treeListRef.current._instance.endCustomLoading();
			showSnackbar('Invite removed', snackbarVariants.success);
			setCacheBust(uuidv4());
		} catch (e) {
			treeListRef.current._instance.endCustomLoading();
			showSnackbar(e.message, snackbarVariants.error);
		}
	};

	const handleResendInviteClick = async ({ workspace_id, parent: email }) => {
		try {
			treeListRef.current._instance.beginCustomLoading();
			const { message } = await sendInvites(getDatahubApi, workspace_id, [email]);
			if (!stringIsNullOrEmpty(message)) {
				throw new Error(message);
			}
			treeListRef.current._instance.endCustomLoading();
			showSnackbar(`Invite resent to ${email}`, snackbarVariants.success);
			setCacheBust(uuidv4());
		} catch (e) {
			showSnackbar(e.message, snackbarVariants.error);
		}
	};

	const handleCreateImpersonation = async (clientUser) => {
		setIsLoadingRecordId(clientUser.key);
		try {
			const { source_client_user_id } = await createImpersonation(getDatahubApi, clientUser.user_id);
			const updatedClientUser = { ...clientUser };
			delete updatedClientUser.impersonating_user_id;
			dataSource.store.update(clientUser.user_id, {
				...clientUser,
				impersonating_user_id: source_client_user_id
			});
			showSnackbar(`impersonation active`, snackbarVariants.success);
			setCacheBust(uuidv4());
			handleOpenClientApp(clientUser.workspace_id);
		} catch (e) {
			showSnackbar(extractError(e), snackbarVariants.error);
		} finally {
			setIsLoadingRecordId(null);
		}
	};

	const handleDeleteImpersonation = async (clientUser) => {
		setIsLoadingRecordId(clientUser.key);
		try {
			await deleteImpersonation(getDatahubApi, clientUser.user_id);
			const updatedClientUser = { ...clientUser };
			delete updatedClientUser.impersonating_user_id;
			dataSource.store.update(clientUser.user_id, updatedClientUser);
			dataSource.store.load();
			showSnackbar(`impersonation cleared`, snackbarVariants.success);
			setCacheBust(uuidv4());
		} catch (e) {
			showSnackbar(extractError(e), snackbarVariants.error);
		} finally {
			setIsLoadingRecordId(null);
		}
	};

	const handleOpenClientApp = (workspaceId) => {
		const newWindow = window.open(
			isNullOrUndefined(workspaceId) ? `https://${appUrl}` : `https://${appUrl}/w/${workspaceId}/inbox`,
			'_blank'
		);
		if (newWindow) {
			newWindow.opener = null;
		}
	};

	const handleUserClick = (data) => {
		setUserData(data);
	};

	const renderUserNameCell = ({ data, value }) => {
		if (!isNullOrUndefined(data.parent)) {
			return null;
		}

		if (isNullOrUndefined(value)) {
			return (
				<div className="wrap">
					<Typography variant="subtitle" noWrap italic>
						<Tooltip title={!isNullOrUndefined(value) ? value : ''}>
							<span>invitation sent</span>
						</Tooltip>
					</Typography>
				</div>
			);
		}

		const hasImpersonation = !isNullOrUndefined(data.impersonating_user_id);
		const areYouImpersonating = data.impersonating_user_id === config.clientUser?.user_id;

		return (
			<div className="wrap">
				<Typography variant="row-link" className="client-users-table__name-cell">
					<Link onClick={() => handleUserClick(data)}>{!isNullOrUndefined(data.user_name) ? data.user_name : ''}</Link>
					{hasImpersonation && (
						<Tooltip
							title={
								areYouImpersonating
									? 'You are impersonating this user'
									: `Impersonation active with user ${data.impersonating_user_id}`
							}
						>
							<span className="client-users-table__name-cell__impersonation-icon">
								<BiGhost />
							</span>
						</Tooltip>
					)}
				</Typography>
			</div>
		);
	};

	const handleWorkspaceCellLinkClick = (url) => {
		const newWindow = window.open(url, '_blank');
		if (newWindow) {
			newWindow.opener = null;
		}
	};

	const renderWorkspaceCell = ({ data, value }) => {
		if (isNullOrUndefined(data.parent)) {
			return null;
		}

		return (
			<div className="wrap">
				<Typography variant="row-link" className="client-users-table__name-cell">
					<Link onClick={() => handleWorkspaceCellLinkClick(`https://${datahubUrl}/clients/workspaces/${data.workspace_id}`)}>
						{value}
					</Link>
				</Typography>
			</div>
		);
	};

	const renderTextCell = ({ value }) => {
		return (
			<div className="wrap">
				<Typography variant="subtitle" noWrap>
					<Tooltip title={!isNullOrUndefined(value) ? value : ''}>
						<span>{value}</span>
					</Tooltip>
				</Typography>
			</div>
		);
	};
	const renderDateCell = ({ value }) => {
		if (isNullOrUndefined(value)) {
			return;
		}

		const dateString = moment.utc(value).local(false).format('h:mmA DD/MM/YYYY');

		return (
			<div className="wrap">
				<Typography variant="subtitle" noWrap>
					<Tooltip title={dateString}>
						<span>{dateString}</span>
					</Tooltip>
				</Typography>
			</div>
		);
	};

	const renderWorkspaceRole = ({ value }) => {
		if (isNullOrUndefined(value)) {
			return;
		}

		return (
			<div className="wrap">
				<Typography variant="subtitle" noWrap>
					<Tooltip title={value}>
						<span>{value.replace(/workspace/gi, '')}</span>
					</Tooltip>
				</Typography>
			</div>
		);
	};

	const renderEmailCell = ({ value }) => {
		return (
			<div className="wrap">
				<Typography variant="row-group">
					<Link className="treenode-parent">{value}</Link>
				</Typography>
			</div>
		);
	};

	const renderStatusCell = ({ data, value }) => {
		if (isNullOrUndefined(data.parent)) {
			return;
		}

		let style;

		if (isNullOrUndefined(data.guid)) {
			style = data.workspace_user_state_id === workspaceStatesLookup.active ? 'info' : 'warning';
		} else {
			style = moment().isAfter(moment.utc(data.expiration).local()) ? 'error' : 'success';
		}

		return (
			<Notification hideIcon center variant={style}>
				{value}
			</Notification>
		);
	};

	const renderMoreOptionsCell = ({ data }) => {
		const hasImpersonation = !isNullOrUndefined(data.impersonating_user_id); // and specific workspace so it wouldn't show for all
		const areYouImpersonating = data.impersonating_user_id === config.clientUser?.user_id;
		const userOrWorkspaceExist = !isNullOrUndefined(data.user_id) || !isNullOrUndefined(data.workspace_id);

		const userLevelOption = [
			{
				label: 'Edit',
				onClick: () => handleUserClick(data)
			}
		];

		const workspaceLevelOptions =
			!isNullOrUndefined(data.workspace_id) && !isNullOrUndefined(data.expiration)
				? [
						{
							label: 'Copy Invitation Link',
							onClick: () => handleCopyToClipboardClick(data)
						},
						{
							label: 'Resend invitation',
							onClick: () => handleResendInviteClick(data)
						},
						{
							label: 'Delete Invitation',
							onClick: () => handleRemoveInvitationClick(data)
						}
					]
				: areYouImpersonating
					? [
							{
								label: 'Visit App',
								onClick: () => handleOpenClientApp(data.workspace_id),
								disabled: !canImpersonate
							},
							{
								label: 'Log out as User',
								onClick: () => handleDeleteImpersonation(data),
								disabled: !canImpersonate
							}
						]
					: [
							{
								label: 'Log in as User',
								onClick: () => handleCreateImpersonation(data),
								disabled: !canImpersonate || data.workspace_user_state_id !== workspaceStatesLookup.active
							}
						].concat(
							hasImpersonation
								? [
										{
											label: 'Remove Impersonation',
											onClick: () => handleDeleteImpersonation(data),
											disabled: !canImpersonate
										}
									]
								: []
						);

		return (
			<div className="wrap">
				{isLoadingRecordId === data.key ? (
					<CircularProgress size={16} />
				) : userOrWorkspaceExist ? (
					<MoreOptionsMenu
						options={!isNullOrUndefined(data.email) ? userLevelOption : workspaceLevelOptions}
						disabled={!isNullOrUndefined(isLoadingRecordId)}
						size="xs"
					/>
				) : null}
			</div>
		);
	};

	return (
		<div className="client-users-table">
			<Header header="Client Users">
				{config.isAdmin && <ToolbarButton title="Export to CSV" icon={<DownloadIcon />} onClick={handleExport} size="md" />}
			</Header>
			<TreeList
				ref={treeListRef}
				autoExpandAll={false}
				dataSource={dataSource}
				showRowLines
				showColumnLines={false}
				showBorders
				dataStructure="plain"
				keyExpr="key"
				parentIdExpr="parent"
				wordWrapEnabled
				filterMode="fullBranch"
			>
				<FilterRow visible applyFilter="auto" />

				<HeaderFilter visible />
				<Selection mode="single" allowSelectAll={false} />
				<ColumnChooser enabled={false} />

				<Column
					dataField="user_name"
					caption="User"
					width={200}
					cellRender={renderUserNameCell}
					allowFiltering
					allowSearch
					allowSorting
				/>

				<Column
					width={250}
					dataField="email"
					caption="Email"
					cellRender={renderEmailCell}
					allowFiltering
					allowSearch
					allowSorting
				/>

				<Column
					dataField="workspace_name"
					caption="Workspace"
					minWidth={150}
					cellRender={renderWorkspaceCell}
					allowFiltering
					allowSearch
					allowSorting
				/>

				<Column
					// dataType="datetime" for some reason, changing this to date time completely muddles the actual time stamp
					dataField="last_activity"
					caption="Last Activity"
					width={170}
					cellRender={renderDateCell}
					allowFiltering
					allowSearch
					allowSorting
				/>

				<Column
					dataField="market_name"
					caption="Market"
					width={120}
					cellRender={renderTextCell}
					allowFiltering
					allowSearch
					allowSorting
				/>

				<Column
					dataField="role_name"
					caption="Role"
					width={120}
					cellRender={renderWorkspaceRole}
					allowFiltering
					allowSearch
					allowSorting
				/>

				<Column
					dataField="membership"
					caption="Membership"
					width={150}
					cellRender={renderStatusCell}
					allowFiltering
					allowSearch
					allowSorting
				/>

				<Column
					// dataType="datetime" for some reason, changing this to date time completely muddles the actual time stamp
					dataField="expiration"
					caption="Invite Expiration"
					width={170}
					cellRender={renderDateCell}
					allowFiltering
					allowSearch
					allowSorting
				/>

				<Column
					width={60}
					caption=""
					cssClass="cell cell--center"
					alignment="center"
					allowFiltering={false}
					allowSearch={false}
					allowSorting={false}
					cellRender={renderMoreOptionsCell}
				/>

				<Paging defaultPageSize={3} />

				<Pager showPageSizeSelector allowedPageSizes={[10, 20, 50]} showInfo />
			</TreeList>

			{!isNullOrUndefined(userData) && (
				<Sheet isOpen onClose={() => setUserData()}>
					<ClientUser userId={userData.user_id} getDatahubApi={getDatahubApi} onClose={() => setUserData()} />
				</Sheet>
			)}
		</div>
	);
};

export default ClientUsers;
