import { useState } from 'react';
import Add from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';
import Button from '@truescope-web/react/lib/components/form/Button';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Inline, { horizontalAlignment } from '@truescope-web/react/lib/components/layout/Inline';
import Tabs from '@truescope-web/react/lib/components/layout/Tabs';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import Sheet from '@truescope-web/react/lib/components/modal/SheetV2';
import { workspaceUserStatesLookup } from '@/constants';
import InviteUsersSheet from './InviteUsersSheet';
import InvitesTable from './InvitesTable';
import UsersTable from './UsersTable';

const WorkspaceUserSection = ({
	workspaceInvites,
	setWorkspaceInvites,
	workspaceUsers,
	setWorkspaceUsers,
	workspacePlan,
	workspaceId,
	hasScopes
}) => {
	const activeUserCount = workspaceUsers.filter((user) => user.workspace_user_state_id === workspaceUserStatesLookup.active).length;
	const disableInvites = activeUserCount >= workspacePlan.max_seats;
	const [isSheetOpen, setIsSheetOpen] = useState(false);

	const handleAddUserClick = () => {
		setIsSheetOpen(true);
	};

	return (
		<Grid container>
			<Grid item>
				<Inline horizontalAlignment={horizontalAlignment.rightAlignSiblings}>
					<Typography variant="h4">Workspace Users</Typography>
					<Tooltip title={disableInvites ? 'The maximum active user count has been reached for this workspace.' : 'Add user'}>
						<span>
							<Button
								fab={<Add />}
								tooltip="Add User"
								variant="primary"
								onClick={handleAddUserClick}
								disabled={disableInvites}
							/>
						</span>
					</Tooltip>
				</Inline>
			</Grid>
			<Grid item>
				<Tabs
					items={[
						{
							label: `Active (${activeUserCount}/${workspacePlan.max_seats})`,
							component: UsersTable,
							props: {
								setWorkspaceUsers,
								workspaceUsers,
								workspacePlan,
								workspaceId
							}
						},
						{
							label: `Invited (${workspaceInvites.length})`,
							component: InvitesTable,
							props: {
								workspaceInvites,
								setWorkspaceInvites,
								workspaceUsers,
								workspacePlan,
								workspaceId
							}
						}
					]}
				/>
			</Grid>
			<Sheet isOpen={isSheetOpen} onClose={() => setIsSheetOpen(false)}>
				<InviteUsersSheet
					hasScopes={hasScopes}
					onClose={() => setIsSheetOpen(false)}
					workspace_id={workspaceId}
					maxSeats={workspacePlan.max_seats}
					usedSeats={activeUserCount}
					onInviteSuccess={(newInvites) => {
						setWorkspaceInvites((prev) => [...prev].concat(newInvites));
					}}
				/>
			</Sheet>
		</Grid>
	);
};

export default WorkspaceUserSection;
