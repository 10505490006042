import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import Typography from '@truescope-web/react/lib/components/layout/Typography';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';

const SignIn = ({ login, errorMessage, isLoading }) => {
	return (
		<div className="sign-in">
			<div className="panel sign-in__left-panel">
				<div className="sign-in__left-panel__content">
					<Grid container>
						<Grid item>
							<img className="sign-in__logo" src="https://app.dev2.truescope.cloud/truescope-logo.png" alt="Truescope" />
						</Grid>
						<Grid item>
							<Typography>Welcome to Datahub. Please sign-in with your internal Truescope account</Typography>
						</Grid>
						<Grid item>
							<button className="sign-in__left-panel__content__sign-in-button" onClick={login} disabled={isLoading}>
								Log In
							</button>
						</Grid>
						{isLoading && (
							<Grid item>
								<LinearProgress className="sign-in__left-panel__content__loading" variant="indeterminate" />
							</Grid>
						)}
						{!stringIsNullOrEmpty(errorMessage) && (
							<Grid item>
								<FormHelperText error>{errorMessage}</FormHelperText>
							</Grid>
						)}
					</Grid>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
