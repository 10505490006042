import { useEffect, useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Typography as MUTypography, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import LoadingIndicator from '@truescope-web/react/lib/components/loading/LoadingIndicator';
import arrayIsNullOrEmpty from '@truescope/utils/lib/arrays/arrayIsNullOrEmpty';
import isNullOrUndefined from '@truescope/utils/lib/objects/isNullOrUndefined';
import { getSelectedEntity, scopeTableFields } from './MediaItemConstants';
import ScopeOptions from './ScopeOptions';

const ScopesPanel = ({ scopes: scopesData, config, onSave, selectOptions, isLoading }) => {
	const [scopes, setScopes] = useState(scopesData);
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		setScopes(scopesData);
	}, [scopesData]);

	const handleOnScopeSave = (scope) => {
		const scopesClone = [...scopes];
		scopesClone.push({ ...getSelectedEntity(scope.scope_id, config.scopeOptions, scopesClone, 'scope'), workspaces: scope.workspaces });
		onSave(scopesClone);
	};

	const handleRemoveScope = (scopeIndex) => {
		setAnchorEl(null);
		const scopesClone = [...scopes];
		scopesClone.splice(scopeIndex, 1);
		onSave(scopesClone, false);
	};

	const renderEmptyState = () => (
		<TableBody>
			<TableRow>
				<TableCell>This item has not been matched to any {scopeTableFields[0].label}.</TableCell>
			</TableRow>
		</TableBody>
	);

	const handleOpenMenu = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return (
		<div className="scopes-panel">
			<Table className="table table--panel">
				{arrayIsNullOrEmpty(scopes) ? (
					renderEmptyState()
				) : (
					<>
						<TableHead>
							<TableRow>
								<TableCell>Scope</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{scopes.map((scope, index) => (
								<TableRow className="scopes-item" key={`${scope.scope_id}-${scope.name}`}>
									<TableCell>{scope.name}</TableCell>

									<TableCell align="right">
										<IconButton aria-controls="row-menu" aria-haspopup="true" onClick={handleOpenMenu}>
											<MoreVert />
										</IconButton>
										<Menu
											id="row-menu"
											anchorEl={anchorEl}
											open={!isNullOrUndefined(anchorEl)}
											onClose={handleCloseMenu}
										>
											<MenuItem onClick={() => handleRemoveScope(index)}>
												<MUTypography>Remove</MUTypography>
											</MenuItem>
										</Menu>
									</TableCell>
								</TableRow>
							))}
							{isLoading && (
								<TableRow>
									<TableCell colSpan={2}>
										<LoadingIndicator />
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</>
				)}
			</Table>

			<ScopeOptions handleOnScopeSave={handleOnScopeSave} selectOptions={selectOptions} />
		</div>
	);
};

export default ScopesPanel;
