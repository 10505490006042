import Content from '@truescope-web/react/lib/components/layout/Content';
import Grid from '@truescope-web/react/lib/components/layout/Grid';
import View from '@truescope-web/react/lib/components/layout/View';
import stringIsNullOrEmpty from '@truescope/utils/lib/strings/stringIsNullOrEmpty';
import { useGoogleAuthContext } from '../../components/GoogleAuth/GoogleAuthProvider';
import Header from '../../components/Header';
import IntervalView from '../../components/IntervalView';
import { errorCodeMapping } from './ErrorDetailsConstants';
import { useParams } from 'react-router';

const ErrorDetails = ({ code = '500' }) => {
	const params = useParams();
	const errorCode = !stringIsNullOrEmpty(params.code) ? params.code : code;
	const [{ isAuthenticated }] = useGoogleAuthContext();

	const renderContent = () => {
		return (
			<Content>
				<Header header="Error" breadcrumbs={false} />
				<Grid container>
					<Grid item>
						<div className="error-message">
							{errorCode} - {errorCodeMapping[errorCode] || 'Unknown'}
						</div>
					</Grid>
				</Grid>
			</Content>
		);
	};

	return isAuthenticated ? <IntervalView>{renderContent()}</IntervalView> : <View>{renderContent()}</View>;
};

export default ErrorDetails;
